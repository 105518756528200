import React, { useEffect } from 'react'
// import { withAuthenticator } from '@aws-amplify/ui-react';
import ForgotPasswordScreen from "../../components/auth/ForgotPasswordScreen"

const ForgotPassword = (props) => {
  const { userInfo } = props;
  useEffect(() => {
  }, [userInfo]);

  return (<>
      <ForgotPasswordScreen userInfo={userInfo}/>
    </>
  )
}

export default ForgotPassword