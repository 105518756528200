import { useEffect } from "react";
import {
  Box,
  Text,
  Container,
  Stack,
  HStack,
  StackDivider,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ArticleItem = (props) => {
  const { article } = props;
  const navigate = useNavigate();

  useEffect(() => {}, [article]);

  async function handleArticleItemClick(article) {
    navigate(`/jjbest/articleadmin/article/${article.id}`);
  }

  return (
    <Box
      as="button"
      borderRadius="md"
      bg="gray.100"
      _hover={{ bg: "gray.300" }}
      color="primary.900"
      onClick={() => handleArticleItemClick(article)}
      key={article.id}
    >
      <Container maxW="3xl">
        <Box
          bg="bg-surface"
          boxShadow={useColorModeValue("sm", "sm-dark")}
          borderRadius="lg"
          p={{
            base: "1",
            md: "2",
          }}
        >
          <Stack spacing="2" divider={<StackDivider />}>
            <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                {/* <Text color="muted" fontWeight="medium">
                  Company Name:{" "}
                </Text> */}
                <Text color="muted" fontWeight="medium">
                  &nbsp;{article.title}
                </Text>
              </HStack>
            </Stack>
            <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                {/* <Text color="muted" fontWeight="medium">
                  Contact:{" "}
                </Text> */}
                <Text color="muted">&nbsp;{article.synopsis}</Text>
              </HStack>
            </Stack>
            {/* <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                <Text color="muted" fontWeight="medium">
                  NAICS:{" "}
                </Text>
                <Text color="muted">&nbsp;{quote.naicsCodeDescription}</Text>
              </HStack>
            </Stack> */}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default ArticleItem;
