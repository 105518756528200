export const gbb_options = [
  {
    optionName: 'Good',
    interestRate: '9.38',
    term: '5'
  },
  {
    optionName: 'Better',
    interestRate: '9.38',
    term: '7'
  },
  {
    optionName: 'Best',
    interestRate: '9.38',
    term: '10'
  },
]