import React, { useEffect } from "react";
import { Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import Sidebar from "../../../components/pageshell/Sidebar";
import Navbar from "../../../components/pageshell/Navbar";
import JJBestHero from "../../../components/jjbest/JJBestHero";
import JJBestContent from "../../../components/jjbest/JJBestContent";
import LoanCalculator from "../../../components/jjbest/LoanCalculator";
import JJBestFooter from "../../../components/jjbest/JJBestFooter";
import JJBestFeatures from "../../../components/jjbest/JJBestFeatures";
import LandingLayoutJJBest from "../../../components/jjbest/LandingLayoutJJBest";
import protectedRoute from "../../../utils/protectedRoute"


const JJBest = (props) => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {
  }, [userInfo]);

  return (<Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container py="8" flex="1" maxW={"8xl"}>
        <LandingLayoutJJBest>
          <JJBestHero />
          <JJBestContent />
          <JJBestFeatures />
          <LoanCalculator />
          <JJBestFooter />
        </LandingLayoutJJBest>
      </Container>
    </Flex>
  );
};

export default protectedRoute(JJBest);
