import { useEffect, useState } from "react";
import {
  Container,
  Text,
  Flex,
  Box,
  Input,
  Stack,
  FormLabel,
  FormControl,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { updateApplication } from "../../../../../graphql/mutations"
import { getApplication } from "../../../../../graphql/queries"
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_jjbest_stepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";
import protectedRoute from "../../../../../utils/protectedRoute"

const Images = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const [application, setApplication] = useState([])
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [linkToImages, setLinkToImages] = useState("");
  const handleLinkToImagesChange = (event) => {
    setLinkToImages(event.target.value);
  };
  

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 3,
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }
    }
    handleFetchApplication()
  }, [userInfo, id]);

  async function handleUpdateApplication(){
    const applicationData = {
      id: id,
      linkToImages: linkToImages,
    }

    try{
      const client = generateClient({authMode: 'userPool'})
      await client.graphql({
        query: updateApplication,
        variables: { input: applicationData }
      })
    } catch(err){
      console.log("error in updateApplication: ", err);
    }
    navigate(`/jjbest/application/submit/${id}`)

  }

  return userInfo === null || userInfo === undefined || application === undefined ?(
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container
                // spacing="5"
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"6xl"}
              >
                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="linkToImages">
                      <FormLabel>Link to Images (optional) </FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter first name..."
                        value={linkToImages}
                        name="linkToImages"
                        onChange={(event) => handleLinkToImagesChange(event)}
                      />
                    </FormControl>
                    
                  </Stack>

                <Stack spacing={3} direction="column" align="center">
                  <Button
                    mt="8"
                    mb="8"
                    bgColor="secondary.700"
                    colorScheme="blue"
                    px="8"
                    rounded="full"
                    size="md"
                    fontSize="md"
                    fontWeight="bold"
                    type="submit"
                    onClick={handleUpdateApplication}
                  >
                    Next
                  </Button>
                </Stack>
                <Stack spacing={3} direction="column" align="center"></Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(Images);
