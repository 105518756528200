import { Box, HStack } from '@chakra-ui/react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate  } from "react-router-dom";
import { BsCaretRightFill } from 'react-icons/bs';

const LogoutNavItem = (props) => {
  const { active, icon, children, label, endElement, href } = props;
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await signOut();
      navigate("/")
    } catch (error) {
      console.log('error signing out: ', error);
    }
  } 
  return (
    
    <HStack
      as="a"
      href={href}
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? 'primary.900' : undefined}
      _hover={{
        bg: 'gray.200',
      }}
      _active={{
        bg: 'gray.200',
      }}
      onClick={handleLogout}
    >
      <Box fontSize="lg" color="secondary.700">
        {icon}
      </Box>
      <Box flex="1" fontWeight="bold" color="primary.900">
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>

  )
}

export default LogoutNavItem;