import { Box, useColorModeValue as mode } from '@chakra-ui/react'
import { NavContent } from './NavContentJJBest'

const Navbar = () => {
  return (
    <Box minH="50px" w={"100%"}>
      <Box as="header" bg={mode('white', 'primary.900')} position="relative" zIndex="10">
        <Box
          as="nav"
          aria-label="Main navigation"
          maxW="9xl"
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          <NavContent.Mobile
            display={{
              base: 'flex',
              lg: 'none',
            }}
          />
          <NavContent.Desktop
            display={{
              base: 'none',
              lg: 'flex',
            }}
          />
        </Box>
      </Box>
    </Box>
    
  )
}

export default Navbar