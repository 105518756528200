import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Container,
  Divider,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  FormLabel,
  FormControl,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { createArticleSection } from '../../../../../graphql/mutations';
import { getArticle } from '../../../../../graphql/queries';
import useStep from '../../../../../utils/useStep';
import { steps } from '../../../../../data/_articleStepData';
import Step from '../../../../../widgets/Step';
import Sidebar from '../../../../../components/pageshell/Sidebar';
import Navbar from '../../../../../components/pageshell/Navbar';

const AddArticleContentMd = props => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const navigate = useNavigate();
  const { slug } = useParams();
  const [articleID, setArticleID] = useState('');
  // const [article, setArticle] = useState([]);
  const [thisSectionOrder, setThisSectionOrder] = useState(1);
  // const [thisArticleSectionData, setThisArticleSectionData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [sectionHeader, setSectionHeader] = useState('');
  const handleSectionHeaderChange = event =>
    setSectionHeader(event.target.value);
  const [sectionContent, setSectionContent] = useState('');
  // const handleSectionContentChange = event =>
  //   setSectionContent(event.target.value);

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 2,
  });

  useEffect(() => {
    async function handleFetchArticle() {
      if (slug) {
        try {
          const slugPartsArray = slug.split('+');
          const tempArticleId = slugPartsArray.pop();
          setArticleID(tempArticleId);
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: getArticle,
            variables: { id: tempArticleId },
          });
          // setArticle(result.data.getArticle);
          if (result.data.getArticle.articleSections.items.length === 0) {
            setThisSectionOrder(0);
            // setThisArticleSectionData([]);
            // setLoading(false);
          } else {
            const tempResult = await result.data.getArticle.articleSections
              .items;
            const index = tempResult.length - 1;
            setThisSectionOrder(tempResult[index].sectionOrder);
            // setThisArticleSectionData(tempResult);
            // setLoading(false);
          }
        } catch (err) {
          console.log('error in handleFetchArticle: ', err);
        }
      }
    }
    handleFetchArticle();
  }, [userInfo, slug]);

  async function handleCreateArticleContent() {
    const contentObj = {
      id: uuidv4(),
      articleID: articleID,
      sectionHeader: sectionHeader,
      sectionContent: sectionContent,
      sectionContentMarkup: sectionContent,
      sectionOrder: thisSectionOrder + 1,
    };
    try {
      const client = generateClient({ authMode: 'userPool' });
      await client.graphql({
        query: createArticleSection,
        variables: { input: contentObj },
      });
      setSectionHeader('');
      setSectionContent('');
      navigate(
        `/jjbest/articleadmin/add-content-md/${
          contentObj.sectionOrder + parseInt(1, 10)
        }+${articleID}`
      );
    } catch (err) {
      console.log('error in createArticleSection: ', err);
    }
  }

  function handleArticleTagNav() {
    navigate(`/jjbest/articleadmin/add-tags/${articleID}`);
  }

  return userInfo === null || userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={'8xl'}>
        <Stack
          spacing={{
            base: '8',
            lg: '6',
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              lg: 'row',
            }}
            justify="space-between"
            align={{
              base: 'start',
              lg: 'center',
            }}
          ></Stack>
          <Stack
            spacing={{
              base: '5',
              lg: '6',
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={'6xl'}>
                <Stack
                  spacing="0"
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container py={{ base: '4', md: '8' }} maxW={'6xl'}>
                <Stack spacing="5">
                  <Divider />
                  <Stack spacing="5" divider={<StackDivider />}>
                    <FormControl id="title">
                      <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={{ base: '1.5', md: '8' }}
                        justify="space-between"
                      >
                        <FormLabel variant="inline">
                          Article Section Header
                        </FormLabel>

                        <Input
                          type="text"
                          id="sectionHeader"
                          size="md"
                          bgColor="white"
                          onChange={event => handleSectionHeaderChange(event)}
                          value={sectionHeader}
                          name="sectionHeader"
                          textAlign="left"
                          maxW={{ md: '3xl' }}
                        />
                      </Stack>
                    </FormControl>

                    <FormControl id="bio">
                      <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={{ base: '1.5', md: '8' }}
                        justify="space-between"
                      >
                        <Box>
                          <FormLabel variant="inline">
                            Section Content
                          </FormLabel>
                          <FormHelperText mt="0" color="muted">
                            Write section content
                          </FormHelperText>
                        </Box>
                        <Box maxW={{ md: '3xl' }}>
                          <MarkdownEditor
                            value={sectionContent}
                            height="200px"
                            width="900px"
                            enablePreview={false}
                            onChange={value => {
                              setSectionContent(value);
                            }}
                          />
                        </Box>
                      </Stack>
                    </FormControl>

                    <Flex direction="row-reverse">
                      <Button
                        mt="8"
                        mb="8"
                        bgColor="secondary.600"
                        colorScheme="blue"
                        px="8"
                        rounded="full"
                        size="md"
                        fontSize="md"
                        fontWeight="bold"
                        type="submit"
                        onClick={handleCreateArticleContent}
                      >
                        Add Section Content
                      </Button>
                    </Flex>
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '5', md: '6' }}
                      justify="space-between"
                      mt="5"
                    >
                      <Box>
                        <Button
                          variant="link"
                          colorScheme="blue"
                          alignSelf="start"
                          leftIcon={<FiArrowLeft fontSize="1.25rem" />}
                          //   onClick={() => handleArticleTagNav()}
                        >
                          Back
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="link"
                          colorScheme="blue"
                          alignSelf="start"
                          leftIcon={<FiArrowRight fontSize="1.25rem" />}
                          onClick={() => handleArticleTagNav()}
                        >
                          Next
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default AddArticleContentMd;
