import React from 'react'
import LandingLayout from '../components/layouts/LandingLayout'
import HomeHero from "../components/heros/HomeHero"
import JobHistoryContainer from '../components/JobHistoryContainer'
import BioContent from '../components/BioContent';
import BioCardContainer from '../components/BioCardContainer';
import Footer from '../components/nav-direct/Footer2';

const Home = () => {
  return (
    <LandingLayout>
      <HomeHero />
      <JobHistoryContainer />
        <BioContent />
        <BioCardContainer />
        <br />
        <Footer />
    </LandingLayout>
  )
}

export default Home