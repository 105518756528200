import forge from '../assets/photos/forgeTaxiWebsite.jpg'
import forksedge from '../assets/photos/forksedgeWebsite.jpg'
import garagegym from '../assets/photos/GarageGymWebsite.png'
import hagerty2 from "../assets/photos/hagertyQuoteWebsite.jpg"
import hagerty5 from "../assets/photos/hagerty5.jpg"
import bcbsm from "../assets/photos/bcbsmWebsite.jpg";

export const posts = [
  {
    id: '1',
    title: 'Hagerty Direct Quote',
    image: hagerty2,
    description: 'I am an expert at direct to consumer insurance sales and service. My digital teams at Hagerty were responsible for driving a 200% increase in new business revenue from the direct web channel. We accomplished this through relentless refinements to the user experience, SEO and Paid Search activities.',
    category: 'Hagerty',
    url: 'https://www.hagerty.com'
  },
  {
    id: '2',
    title: 'Hagerty Valuation Tools ',
    image: hagerty5,
    description: "I spearheaded efforts at Hagerty to convert their price guide data into a revolutionary digital asset. SEO efforts garnered over 4.2 million visitors per year.  Valuation tools as an insurance adjacency accelerated Hagerty's brand and revenue growth. Not only did we see a surge in quotes from valuation tools, we also formed partnerships with other insurers as Hagerty was viewed as the industry leader in classic car values.",
    category: 'Hagerty',
    url: 'https://www.hagerty.com/valuation-tools/mercedes~benz/300sel/1970/1970-mercedes~benz-300sel-6.3'
  },
  {
    id: '3',
    title: 'Rebrand and digital transformation of Forge Insurance',
    image: forge,
    description: 'Forge Insurance was rebranded from Amalgamated Casualty Insurance Company. Forge has roots in commercial auto insurance to service the needs of taxi drivers and owners in the mid-Atlantic area.  We expanded their underwriting appetite to include other business segments, moved them to the Majesco policy platform and converted their existing book mid-term.',
    category: 'Forge Insurance Group',
    url: 'https://www.forgeinsurance.com'
  },
  {
    id: '4',
    title: "Fork's Edge exists to take the hassle out of creating, collecting, and organizing recipe data",
    image: forksedge,
    description: 'I love to cook and entertain, but I am frustrated with the general disorganization of recipes. So, I created a suite of tools to leverage image to text AI features, streaming temperature data from WiFi connected Arduino devices, Alexa enabled voice search of stored receipes, and more.',
    category: 'Passion Project',
    url: 'https://www.forksedge.com'
  },
  {
    id: '5',
    title: 'Garage Gym Sensei exists to remove the organization hassle for home gymers',
    image: garagegym,
    description: "A few years ago I set out to re-teach myself how to code. I enjoy doing Cross Fit style workouts in my home gym, but I wanted a better way of matching workouts to the equipment I own. I've expanded it to include other activities like tennis drills and incorporated a scoring/metrics system to better track progress.",
    category: 'Passion Project',
    url: 'https://www.garagegymsensei.com'
  },
  
  
  {
    id: '6',
    title: 'Blue Cross Blue Shield Interplan Outstanding Performance Award',
    image: bcbsm,
    description: 'I love to solve problems especially when it involves an innovative digital solution.  In 2006 I won the prestigious outstanding performance award for an application I wrote. The leading-edge application solved a challenging business problem that involves the adjudication of claims betweeen one Blues plan and another. I had a lengthy and successful career at BCBSM where I ultimately was the corporate lead for data quality and data governance at one of the largest data warehouses in the State of Michigan.',
    category: 'BCBSM',
    url: 'https://www.bcbsm.com'
  },
]