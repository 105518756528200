import { useState, useEffect } from 'react';
import { getUrl } from 'aws-amplify/storage';
import {
  Box,
  CircularProgress,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { BlogAuthor } from '../articles/BlogAuthor';
import { BlogMedia } from '../articles/BlogMedia';

const ArticleHero = props => {
  const { article } = props;
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [synopsis, setSynopsis] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const color1 = useColorModeValue('gray.600', 'gray.400');

  useEffect(() => {
    const getOneFormattedImage = async image => {
      const signedURL = await getUrl({ key: image.articleImageFile });
      let urlResultObj = {
        src: signedURL,
        id: image.id,
      };
      return urlResultObj;
    };

    const getImageFilesList = async imageList =>
      Promise.all(imageList.map(async i => getOneFormattedImage(i)));
    const buildImageArray = async article =>
      getImageFilesList(article.articleImages.items);
    const getImageData = async () => {
      const imageArray = await buildImageArray(article);
      setImages(imageArray);
      setTitle(article.title);
      setAuthor(article.author);
      setSynopsis(article.synopsis);
      setLoading(false);
    };
    getImageData(buildImageArray, article);
  }, [article]);

  return loading || images === null || undefined ? (
    <CircularProgress />
  ) : (
    <Flex direction="column" flex="1" overflow="auto" px="10" pt="8">
      <Stack spacing={4} divider={<StackDivider />}>
        <Grid
          templateColumns={{
            base: '1fr',
            md: '1fr 24rem',
          }}
          columnGap={{
            base: '12',
            lg: '20',
          }}
          rowGap="10"
        >
          <BlogMedia alt="Get to Know JJ Best" src={images[0]} />
          <Flex direction="column" h="full">
            <Box flex="1">
              <Heading size="xl" mt="6" mb="4">
                {title}
              </Heading>
              <Text fontSize="lg" color={color1} lineHeight="tall">
                {synopsis}
              </Text>
            </Box>
            <BlogAuthor mt="8" name={author} />
          </Flex>
        </Grid>
      </Stack>
    </Flex>
  );
};

export default ArticleHero;
