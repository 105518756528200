import {Container, Link, VStack, Text } from '@chakra-ui/react'

const JJBestDisclosures = () => (
    <Container
    as="footer"
    role="contentinfo"
    py={{
      base: '12',
      md: '16',
    }}
    maxW={'9xl'} p="5" bg="gray.100" color="white" mx="auto"
  >
      <VStack spacing={{ base: '4', md: '5' }}>
        {/* <Stack justify="space-between" direction="row" align="center">
          <ButtonGroup variant="tertiary.accent">
            <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin />} />
            <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub />} />
            <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter />} />
          </ButtonGroup>
        </Stack> */}
        <Text fontSize="md" color="black">
          Copyright &copy; {new Date().getFullYear()}
        </Text>
        <Text fontSize="md" color="black">
        Simple Interest, Fixed Rate. Programs and Rates Subject to Credit Approval, Income, Collateral and other Conditions. J.J. Best Banc & Company operates under the name J.J. Best & Company in Indiana and Idaho. <Link color='cyan.400' href='https://nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/934656' isExternal>NMLS #: CL-934656 </Link>.
        </Text>
      </VStack>
    </Container>
)

export default JJBestDisclosures;