export const steps = [
  {
    title: 'Your vehicle',
    // description: 'Description',
  },
  {
    title: 'Yourself',
    //description: 'Description',
  },
  {
    title: 'Your employment',
    //description: 'Description',
  },
  {
    title: 'Optional images',
    //description: 'Description',
  },
  {
    title: 'Get a quote',
    //description: 'Description',
  },
]