import { Container, Stack, SimpleGrid, Heading } from "@chakra-ui/react";
// import { Logo } from './Logo'
import WorkHistoryCard from "../widgets/WorkHistoryCard";
import { posts } from "../data/_workHistoryData";

const BioCardContainer = () => (
  <Container maxW={"8xl"} p="5" bgColor="white">
    <Stack align={["center", "center", "center", "center"]}>
      <Heading
        as="h1"
        size="xl"
        lineHeight="short"
        fontWeight="bold"
        color="secondary.600"
        mb="5"
        maxW={{
          base: "unset",
          lg: "800px",
        }}
      >
        Some examples of my work...
      </Heading>
    </Stack>

    <SimpleGrid
      columns={{
        base: 1,
        md: 2,
      }}
      spacingX="10"
      spacingY={{
        base: "8",
        md: "14",
      }}
    >
      {posts.map((post, id) => (
        <WorkHistoryCard key={id} {...post} />
      ))}
    </SimpleGrid>
  </Container>
);

export default BioCardContainer;
