import { Avatar, Stack, Text } from '@chakra-ui/react'


const Testimonial = (props) => {
  const { avatarUrl, name, quote, state_name } = props
  return (
    <Stack spacing="8" align="center" textAlign="center">
      {/* <Logo /> */}
      <Text textStyle={{ base: 'lg', md: 'xl' }} fontWeight="medium">
        {quote}
      </Text>
      
      <Stack spacing="4" align="center">
        <Avatar src={avatarUrl} size="lg" name={name} />
        <Stack spacing="1">
          <Text fontWeight="semibold">{name}</Text>
          <Text color="fg.muted">
            {state_name}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Testimonial;