import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { fetchUserAttributes  } from 'aws-amplify/auth';

// eslint-disable-next-line react/display-name
const protectedRoute = (Comp, route = '/auth/login') => (props) => {
  const navigate = useNavigate();
  async function checkAuthState() {
    try {
      await fetchUserAttributes();
    } catch (err) {
      navigate(route);
    }
  }
  useEffect(() => {
    checkAuthState()
  })
  return <Comp {...props} />
}

export default protectedRoute