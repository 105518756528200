import React, { useEffect } from "react";
import {
  Container,
  Flex,
  Heading,
  Stack,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
// import { listProfiles } from "../../graphql/queries";
// import FileRadomnizerCard from "../../components/admin/FileRandomizerCard";
// import InviteUser from "../../components/admin/InviteUser";
import Sidebar from "../../components/pageshell/Sidebar";
import Navbar from "../../components/pageshell/Navbar";
import Scratchpad from "../../components/admin/Scratchpad";
import protectedRoute from "../../utils/protectedRoute";

const Admin = (props) => {
  const { userInfo } = props;
  // const [loading, setLoading] = useState(true);
  // const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {}, [userInfo]);

  return  userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container py="8" flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Stack spacing="1">
              <Heading fontWeight="medium">Admin Dashboard</Heading>
            </Stack>
          </Stack>
         
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2 }}
            columnGap={8}
            rowGap={{ base: 10, md: 16 }}
          >
            {/* <FileRadomnizerCard />
            <InviteUser /> */}
            
            <Scratchpad />
          </SimpleGrid>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(Admin);
