import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import {
  Button,
  Text,
  Container,
  FormControl,
  Input,
  Stack,
  StackDivider,
  Wrap,
  Box,
  Flex,
  CircularProgress,
  useColorModeValue,
  WrapItem,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { listTags } from '../../graphql/queries';
import { createTag, deleteTag } from '../../graphql/mutations';

const _ = require('lodash');

const CreateTag = () => {
  const [tagName, setTagName] = useState('');
  const [sortedTags, setSortedTags] = useState([]);
  const handleSetTagChange = event => setTagName(event.target.value);
  const [loading, setLoading] = useState(true);
  const color1 = useColorModeValue('sm', 'sm-dark');

  const handleSortedTags = async () => {
    try {
      const client = generateClient({ authMode: 'userPool' });
      let tagResult = await client.graphql({
        query: listTags,
      });
      const result = _.orderBy(
        tagResult.data.listTags.items,
        ['tagName'],
        ['asc']
      );
      setSortedTags(result);
    } catch (err) {
      console.log('error in handleSortedTags: ', err);
    }
  };

  useEffect(() => {
    setLoading(false);
    handleSortedTags();
  }, []);

  async function handleCreateTag() {
    if (tagName === '') {
      //do nothing
    } else {
      const tagObj = {
        id: uuidv4(),
        tagName: tagName,
      };
      try {
        const client = generateClient({ authMode: 'userPool' });
        await client.graphql({
          query: createTag,
          variables: { input: tagObj },
        });
      } catch (err) {
        console.log('error in handleCreateTag: ', err);
      }
      setTagName('');
      handleSortedTags();
    }
  }

  async function handleChipClick(thisTag) {
    const deleteTagObj = {
      id: thisTag.id,
    };
    try {
      const client = generateClient({ authMode: 'userPool' });
      await client.graphql({
        query: deleteTag,
        variables: { input: deleteTagObj },
      });
    } catch (err) {
      console.log('error in deleteTag: ', err);
    }
    handleSortedTags();
  }

  return loading || sortedTags === null || undefined ? (
    <CircularProgress />
  ) : (
    <Container
      py={{
        base: '4',
        md: '8',
      }}
      maxW={'6xl'}
    >
      <Stack spacing="5" divider={<StackDivider />}>
        <Stack
          direction={{
            base: 'column',
            lg: 'row',
          }}
          spacing={{
            base: '5',
            lg: '8',
          }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Current Article Tags
            </Text>
            <Text color="muted" fontSize="sm">
              Click a tag to delete
            </Text>
          </Box>
          <Wrap width="full" spacing="6">
            {sortedTags.map(tag => {
              return (
                <WrapItem key={tag.id}>
                  <Box
                    as="button"
                    key={tag.id}
                    borderRadius="md"
                    bg="secondary.700"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    px={4}
                    py={1}
                    h={8}
                    onClick={() => handleChipClick(tag)}
                  >
                    {tag.tagName}
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </Stack>

        <Stack
          direction={{
            base: 'column',
            lg: 'row',
          }}
          spacing={{
            base: '5',
            lg: '8',
          }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Create more tags
            </Text>
          </Box>

          <Box
            as="form"
            bg="bg-surface"
            boxShadow={color1}
            borderRadius="lg"
            // {...props}
          >
            <Stack
              spacing="5"
              px={{
                base: '4',
                md: '6',
              }}
              py={{
                base: '5',
                md: '6',
              }}
            >
              <Stack
                spacing="6"
                direction={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <FormControl id="tag-name">
                  <Input
                    id="tagName"
                    maxLength={255}
                    name="tagName"
                    type="tagName"
                    onChange={handleSetTagChange}
                    value={tagName}
                  ></Input>
                </FormControl>
              </Stack>
            </Stack>
            {/* <Divider /> */}
            <Flex
              direction="row-reverse"
              py="4"
              px={{
                base: '4',
                md: '6',
              }}
            >
              <Button
                bgColor="primary.900"
                color="white"
                onClick={handleCreateTag}
              >
                Create article tag
              </Button>
            </Flex>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default CreateTag;
