import { FaLinkedin } from 'react-icons/fa'

export const links = [
  {
    title: 'Welcome',
    links: [
      {
        label: 'Login',
        href: '/',
      },
     
    ],
  },
  
]
export const socialLinks = [
  // {
  //   label: 'Facebook',
  //   icon: <FaFacebook />,
  //   href: 'https://facebook.com/joe.niemer',
  // },
  // {
  //   label: 'Instagram',
  //   icon: <FaInstagram />,
  //   href: 'https://www.instagram.com/washingtonhaus/',
  // },
  {
    label: 'LinkedIn',
    icon: <FaLinkedin />,
    href: 'https://www.linkedin.com/in/joe-niemer/',
  },
  // {
  //   label: 'Twitter',
  //   icon: <FaTwitter />,
  //   href: 'https://twitter.com/JoeNiemer',
  // },
]
export const footerLinks = [
  {
    label: 'Login',
    href: '/auth/login',
  },
]