import { useEffect, useState } from "react";
import {
  Container,
  Text,
  Flex,
  Box,
  Input,
  Stack,
  FormLabel,
  FormControl,
  Select,
  Button,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { v4 as uuidv4 } from "uuid";
import { createApplication } from "../../../../graphql/mutations"
import useStep from "../../../../utils/useStep";
import { steps } from "../../../../data/_jjbest_stepData";
import Step from "../../../../widgets/Step";
import Sidebar from "../../../../components/pageshell/Sidebar";
import Navbar from "../../../../components/pageshell/Navbar";
import protectedRoute from "../../../../utils/protectedRoute"

const AppStart = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [collateralType, setCollateralType] = useState("");
  const handleCollateralTypeChange = (event) => {
    setCollateralType(event.target.value);
  };
  const [vehicleYear, setVehicleYear] = useState("");
  const handleVehicleYearChange = (event) => setVehicleYear(event.target.value);
  const [make, setMake] = useState("");
  const handleMakeChange = (event) => setMake(event.target.value);
  const [model, setModel] = useState("");
  const handleModelChange = (event) => setModel(event.target.value);
  const [purchasePrice, setPurchasePrice] = useState("40000");
  const handlePurchasePriceChange = (event) =>
    setPurchasePrice(event.target.value);
  const [loanAmount, setLoanAmount] = useState("35000");
  const handleLoanAmountChange = (event) => setLoanAmount(event.target.value);
  const [applicationID, setApplicationID] = useState("");
  const [dateTimeString, setDateTimeString] = useState("");

  const [effDate, setEffDate] = useState("");
  const [yearOptions, setYearOptions] = useState([]);

  const collateralTypeOptions = [
    "Auto",
    "Preapproved Auto",
    "Boat",
    "Preapproved Boat",
    "Aircraft",
    "Motorcycle",
    "RV",
    "Coin",
  ];

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 0,
  });

  useEffect(() => {
    const arrayRange = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
      );
    const handleYearArray = async () => {
      let t = new Date();
      t.setDate(t.getDate() + 1);
      const ret = `${t.getFullYear()}-${String(t.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(t.getDate()).padStart(2, "0")}`;
      setEffDate(ret);
      let result = arrayRange(1900, t.getFullYear(), 1);
      setYearOptions(result);
    };
    handleYearArray();
    const timestamp = new Date();
    setDateTimeString(timestamp.toUTCString());
    setApplicationID(uuidv4());
  }, [userInfo]);

  // async function handleResetDefaults(){
  //   setCollateralType("");
  //   setVehicleYear("");
  //   setMake("")
  //   setModel("");
  //   setPurchasePrice(0)
  //   setLoanAmount(0)
  // }

  async function handleApplicationStart() {
    const tempApplicationName = collateralType + " " + vehicleYear + " " + make + " " + model + " " + purchasePrice.toString() + " " + loanAmount.toString() + " " + dateTimeString
    const applicationData = {
      id: applicationID,
      applicationGroupName: 'all',
      applicationSource: 'direct-web',
      applicationName: tempApplicationName,
      collateralType: collateralType,
      vehicleYear: vehicleYear,
      make: make,
      model: model,
      purchasePrice: purchasePrice,
      loanAmount: loanAmount,
      firstName: "",
      lastName: "",
      applicationType: "",
      cellPhone: "",
      email: "",
      ssn: "",
      dob: "",
      address: "",
      city: "",
      applicantState: "",
      zipCode: "",
      yearsAtResidence: "",
      residenceType: "",
      employmentStatus: "",
      employer: "",
      employmentPosition: "",
      yearsInPosition: "",
      yearlyIncome: "",
      linkToImages: "",
      disclaimerCheck: "",
      insuranceInterest: "",
    };

    try{
      const client = generateClient({authMode: 'userPool'})
      await client.graphql({
        query: createApplication,
        variables: {input: applicationData},
      })

    } catch(err){
      console.log("error in createApplication: ", err);
    }
    navigate(`/jjbest/application/applicant/${applicationID}`)
  }

  return userInfo === null || userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"6xl"}
              >
                <Stack
                  spacing="5"
                  px={{
                    base: "4",
                    md: "6",
                  }}
                  py={{
                    base: "5",
                    md: "6",
                  }}
                >
                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="collateralType">
                      <FormLabel>Vehicle Type</FormLabel>
                      <Select
                        placeholder="select an vehicle type"
                        size="md"
                        value={collateralType}
                        onChange={handleCollateralTypeChange}
                      >
                        {collateralTypeOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="vehicleYear">
                      <FormLabel>Year</FormLabel>
                      <Select
                        placeholder="select a year"
                        size="md"
                        value={vehicleYear}
                        onChange={handleVehicleYearChange}
                      >
                        {yearOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="make">
                      <FormLabel>Make</FormLabel>
                      <Input
                        type="text"
                        placeholder="Enter make..."
                        value={make}
                        name="make"
                        onChange={(event) => handleMakeChange(event)}
                      />
                    </FormControl>
                    <FormControl id="shareInd">
                      <FormLabel>Model</FormLabel>
                      <Input
                        type="text"
                        placeholder="Enter model..."
                        value={model}
                        name="model"
                        onChange={(event) => handleModelChange(event)}
                      />
                    </FormControl>
                  </Stack>

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="purchasePrice">
                      <FormLabel>Purchase Price</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                        >
                          $
                        </InputLeftElement>
                        <Input
                          placeholder="Enter purchase price"
                          type="number"
                          value={purchasePrice}
                          name="purchasePrice"
                          onChange={(event) => handlePurchasePriceChange(event)}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl id="loanAmount">
                      <FormLabel>Loan Amount</FormLabel>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          color="gray.300"
                          fontSize="1.2em"
                        >
                          $
                        </InputLeftElement>
                        <Input
                          type="number"
                          placeholder="Enter loan amount..."
                          value={loanAmount}
                          name="loanAmount"
                          onChange={(event) => handleLoanAmountChange(event)}
                        />
                      </InputGroup>
                    </FormControl>
                  </Stack>
                </Stack>

                <Stack spacing={3} direction="column" align="center">
                  <Button
                    mt="8"
                    mb="8"
                    bgColor="secondary.700"
                    colorScheme="blue"
                    px="8"
                    rounded="full"
                    size="md"
                    fontSize="md"
                    fontWeight="bold"
                    type="submit"
                    onClick={handleApplicationStart}
                  >
                    Next
                  </Button>
                </Stack>
                <Stack spacing={3} direction="column" align="center"></Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(AppStart);
