// import { FaArrowTrendDown, FaPersonRunning, FaDownLong, FaTrophy   } from "react-icons/fa6";
import { FaArrowDown , FaTrophy, FaRunning } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";

export const features = [
  {
    name: "Financing Collector Vehicles & Boats Since 1993 ",
    description: "Our team of collector vehicle experts will guide you through every aspect of financing your dream. We look out for your best interests whether it's identifying digital fraud, providing guaranteed value insurance, or leveraging our time-tested industry relationships.",
    icon: FaTrophy,
  },
  {
    name: 'Best Rates & Terms for Lowest Monthly Payments',
    description: 'With low fixed rates and longer term options, we offer the lowest and most affordable monthly payments saving you money. Affording your dream car, boat, RV or airplane is easier than you ever imagined.',
    icon: FaArrowDown,
  },
  {
    name: 'Fast Approval & Unmatched Personal Service',
    description: 'Receive approval or get pre-qualified in a few minutes with no obligation. Our team of knowledgeable collector enthusiasts pick up the phone and walk you through every detail so you can drive the dream. Call Today!',
    icon: FaRunning  ,
  },
  {
    name: 'Classic / Exotic Car & Boat Expertise',
    description:
      "Unlike your local bank, we know the difference between a collector car and a used car. Since 1993, we've specialized in understanding collector values. Our loan officers attend 30 car and/or boat shows per year and we're collectors ourselves.",
    icon: AiFillBank,
  },
  // {
  //   name: 'Low Interest Rates & Longer Term Options',
  //   description:
  //     "Unlike your local bank, we know the difference between a collector car and a used car--namely why a 40 year old car might cost over $100,000. We offer lower rates and longer term options, so you can drive the dream.",
  //   icon: FaTrophy,
  // },
  
  // {
  //   name: 'Accessible',
  //   description:
  //     "Accessibility first. That's why we pay attention to accessibility right from the start.",
  //   icon: FaAccessibleIcon,
  // },
]