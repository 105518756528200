import { Flex } from "@chakra-ui/react"
// import Navbar from '../nav-direct/Navbar'

const LandingLayout = (props) => {
  return (
    <Flex
      direction="column"
      align="center"
      // maxW={{ xl: "1200px" }}
      p="1"
      m="0 auto"
      {...props}
    >
      {/* <Navbar /> */}
      {props.children}
    </Flex>
  );
}

export default LandingLayout