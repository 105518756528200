import {
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Box,
  Text,
} from '@chakra-ui/react';
import { collector_cars } from '../../data/_jjbest_classicCarExamples';
import VehicleCard from './VehicleCard';

const ClassicCarExamples = () => (
  <Box as="section" py={{ base: '16', md: '24' }}>
    <Container maxW={'8xl'} p="5" bgColor="gray.50">
      <Stack spacing="3" mb="10">
        <Heading size={{ base: 'md', md: 'xl' }}>
          What is a classic car?
        </Heading>
      </Stack>
      <Stack spacing={{ base: '12', md: '16' }}>
        <Stack spacing={{ base: '4', md: '5' }} maxW="3xl">
          <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
            Generally classified as 45+ year old cars, though we also cover
            vintage cars from as early as 1900. These cars can come in many
            forms such as:
          </Text>
        </Stack>
        <SimpleGrid
          columns={{ base: 1, sm: 2, lg: 5 }}
          gap={{ base: '8', lg: '12' }}
        >
          {collector_cars.map(car => (
            <VehicleCard key={car.id} car={car} />
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
);

export default ClassicCarExamples;
