import hagertyLogo from "../assets/photos/hagertyLogo.png"
import forgeLogo from "../assets/photos/ForgeLogoColor.svg";
import forksedgeLogo from "../assets/photos/forksedge_blackwordmark.svg";
import bcbsmLogo from "../assets/photos/bcbsmLogo.png";
import accentureLogo from "../assets/photos/accentureLogo.png";

export const stats = [
  {
    label: 'Forge',
    description: 'One of the fastest growing headless UI libraries in the world',
    value: '1.3M',
    cta: 'Learn more',
    image: forgeLogo,
  },
  {
    label: 'Forks Edge',
    description: 'Appreciated by developers for its simplicity and flexibility',
    value: '30.3K',
    cta: 'Like us on GitHub',
    image: forksedgeLogo,
  },
  {
    label: 'Hagerty',
    description: 'A team of talented and passionate developers ready to help',
    value: '8',
    cta: 'Meet the team',
    image: hagertyLogo,
  },
  {
    label: 'BCBSM',
    description: 'Join our friendly and helpful developer community on Discord',
    value: '8.3K',
    cta: 'Join Discord',
    image: bcbsmLogo,
  },
  {
    label: 'Accenture',
    description: 'Join our friendly and helpful developer community on Discord',
    value: '8.3K',
    cta: 'Join Discord',
    image: accentureLogo,
  },
]