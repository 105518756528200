import { v4 as uuidv4 } from "uuid";
export const calculateLoan = async (loanAmount, interestRate, loanTerm, id, optionName) => {
  try {
    const tempPrincipal = parseFloat(loanAmount);
    const tempInterest = parseFloat(interestRate) / 100 / 12;
    const tempPayments = loanTerm * 12;
    // compute the monthly payment figure
    const powers = Math.pow(1 + tempInterest, tempPayments); //Math.pow computes powers
    const tempMonthlyPayment =
      (tempPrincipal * powers * tempInterest) / (powers - 1);
    let loanData;
    if (isFinite(tempMonthlyPayment)) {
      loanData = {
        id: uuidv4(),
        applicationId: id,
        optionName: optionName,
        loanTerm: loanTerm,
        interestRate: interestRate,
        loanAmount: loanAmount,
        monthlyPayment: tempMonthlyPayment.toFixed(2),
        totalPayments: (tempMonthlyPayment * tempPayments).toFixed(2),
        totalInterest: (tempMonthlyPayment * tempPayments - tempPrincipal).toFixed(2)
      }
    }
    return loanData;
  } catch (err) {
    console.log("error calculateLoan: ", err);
  }
};