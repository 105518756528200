export const steps = [
  {
    title: 'Add the title and synopsis',
    //description: 'Description',
  },
  {
    title: 'Add an image',
    //description: 'Description',
  },
  {
    title: 'Add content',
    //description: 'Description',
  },
  {
    title: 'Add tags',
    //description: 'Description',
  },
]