
export const testimonials = [
  {
    avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
    company: 'None',
    name: 'Taylor B',
    state_name: 'Kentucky',
    quote:
      '"Get with Ryan Valadao! The man does not miss a phone call and is an absolute pleasure to work with. If I need anything in the future, I know who I will call."',
  },
  {
    avatarUrl: 'https://tinyurl.com/299apdk5',
    company: 'None',
    name: 'Jenna P',
    state_name: 'Virginia',
    quote:
      '"Everyone I worked with at J.J. Best--Ryan and Mary then Debbie for insurance, were really helpful and FAST. They know the vehicles they are dealing with and INSPECT them to make sure you are getting a good deal. I cannot recommend them highly enough."',
  },
  {
    avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
    company: 'None',
    name: 'Rich L.',
    state_name: 'Florida',
    quote:
      '"My loan officer Chris and loan processor Nina were excellent for my first classic car buying experience and I had a lot of questions! Chris returned every call, email & text in minutes and went above and beyond to help with my onsite VIN inspection, out of state taxes and more. I got a low rate too!"',
  },
  {
    avatarUrl: 'https://tinyurl.com/299apdk5',
    company: 'None',
    name: 'Peter H.',
    state_name: 'New Jersey',
    quote:
      '"I cannot say enough good things about Joe Forte and JJ Best Banc. I have literally closed a ton of loans with them over the years. Their level of customer service, knowledge, expertise, follow up, professionalism, time invested and quickness are top-notch and above reproach. 10 outta 10!"',
  },
  {
    avatarUrl: 'https://tinyurl.com/299apdk5',
    company: 'None',
    name: 'Justin L.',
    state_name: 'Texas',
    quote:
      '"I would like to thank Brandon for making my first classic car purchase as fast and easy as possible. He has been very helpful & has answered every question I have had. I highly recommend him and his team! Thanks Brandon!"',
  },
  {
    avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
    company: 'None',
    name: 'Mike W.',
    state_name: 'Misouri',
    quote:
      '"Michael Ponte delivered on every promise he made and then some. He had to deal with a difficult auction house and got the deal done in 2 days. He called me back on his own time after business hours. Mr. Ponte made my dream of owning a classic car a reality."',
  },
]