import React, { useEffect, useState } from 'react';
import {
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Box,
  Link,
  Text,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { generateClient } from 'aws-amplify/api';
import { listArticles } from '../../../../graphql/queries';
import Sidebar from '../../../../components/pageshell/Sidebar';
import Navbar from '../../../../components/pageshell/Navbar';
import CreateTag from '../../../../components/articles/CreateTag';
import ArticleItem from '../../../../components/articles/ArticleItem';
import protectedRoute from '../../../../utils/protectedRoute';

const ArticleAdmin = props => {
  const { userInfo } = props;
  const [articles, setArticles] = useState([]);
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {
    const handleFetchArticles = async () => {
      if (userInfo) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let articleResult = await client.graphql({
            query: listArticles,
          });
          setArticles(articleResult.data.listArticles.items);
        } catch (err) {
          console.log('error in handleFetchArticles: ', err);
        }
      }
    };
    handleFetchArticles();
  }, [userInfo]);

  return userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container py="8" flex="1" maxW={'8xl'}>
        <Stack
          spacing={{
            base: '8',
            lg: '6',
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              lg: 'row',
            }}
            justify="space-between"
            align={{
              base: 'start',
              lg: 'center',
            }}
          >
            <Stack spacing="1">
              <Heading fontWeight="medium">Blog Article Admin</Heading>
            </Stack>
          </Stack>
          <Stack
            spacing={{
              base: '5',
              lg: '6',
            }}
          >
            <Box as="section" bg="bg-surface" bgColor="gray.100">
              <Container
                py={{
                  base: '4',
                  md: '6',
                }}
              >
                <Stack
                  spacing="3"
                  direction={{
                    base: 'column',
                    sm: 'row',
                  }}
                  justify="space-between"
                >
                  <Box>
                    <Text fontSize="lg" fontWeight="medium">
                      Create a new article?
                    </Text>
                  </Box>
                  <Link href="/jjbest/articleadmin/add-article">
                    <Button bgColor="secondary.700" color="white" size="lg">
                      New article
                    </Button>
                  </Link>
                </Stack>
              </Container>
            </Box>

            <SimpleGrid
              columns={{
                base: 1,
                md: 1,
              }}
              gap="6"
            >
              {' '}
              <CreateTag  />
            </SimpleGrid>
          </Stack>
          <Stack
            spacing={{
              base: '5',
              lg: '6',
            }}
          >
            <SimpleGrid
              columns={{
                base: 1,
                md: 3,
              }}
              gap="6"
            >
              {articles.map((article, id) => (
                <ArticleItem key={id} article={article} />
              ))}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(ArticleAdmin);
