import { Box, Container, Text, Spinner } from '@chakra-ui/react'

const LoadingContainer = (props) => {
  const { loadingMessage } = props
  return (
    <Container maxW='2xl' centerContent>
        <Box padding='4' color='black' maxW='md'>
        <Text fontSize='xl'>{loadingMessage}</Text>
            
        </Box>
        <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
            />
    </Container>
  )
}

export default LoadingContainer