import { Container, Stack, Text } from '@chakra-ui/react'
import Logo from './Logo'

const Footer = () => (
  <Container
    as="footer"
    role="contentinfo"
    py={{
      base: '12',
      md: '16',
    }}
    maxW={'8xl'} p="5" bg="primary.100" color="white" mx="auto"
  >
    <Stack
      spacing={{
        base: '4',
        md: '5',
      }}
    >
      <Stack justify="space-between" direction="row" align="center">
        <Logo />
        <Text fontSize="sm" color="primary.900">
        &copy; {new Date().getFullYear()} Marymont Labs, Inc. All rights reserved.
      </Text>
        {/* <ButtonGroup variant="tertiary">
          <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
          <IconButton
            as="a"
            href="#"
            aria-label="Twitter"
            icon={<FaTwitter fontSize="1.25rem" />}
          />
        </ButtonGroup> */}
      </Stack>
      <br />
      
    </Stack>
  </Container>
)

export default Footer;