import React, { useEffect, useState } from 'react';
import { Flex, Stack, Box, Text } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { MdDashboard } from 'react-icons/md';
import { BiCog } from 'react-icons/bi';
// import Logo from "../../components/nav-direct/Logo";
import NavGroup from './NavGroup2';
import NavItem from './NavItem';
import LogoutNavItem from './LogoutNavItem';
import { links } from '../../data/_client_navData';
import LoadingContainer from '../../widgets/LoadingContainer';

const Sidebar = props => {
  const { userInfo } = props;
  const [linkArray, setLinkArray] = useState([]);
  const [username, setUsername] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoadingMessage('Customizing the navigation menu...');
    const handleLinkArray = () => {
      if (userInfo) {
        if (userInfo.nickname === 'Admin') {
          setLinkArray(links);
          setLoading(false);
        } else {
          let linkData = links.filter(link => {
            return link.client === userInfo.nickname;
          });
          setLinkArray(linkData);

          setLoading(false);
        }
      }
    };
    const handleUsername = () => {
      if (userInfo) {
        const name = userInfo.name + ' ' + userInfo.family_name + ' links';
        setUsername(name);
      }
    };
    handleLinkArray();
    handleUsername();
  }, [userInfo]);

  return loading || userInfo === null || undefined ? (
    <LoadingContainer loadingMessage={loadingMessage} />
  ) : (
    <div>
      <Box height="100vh" overflow="hidden" position="relative">
        <Flex h="full" id="app-container">
          <Box w="64" bg="gray.50" color="white" fontSize="sm">
            <Flex h="full" direction="column" px="4" py="4">
              <Text
                px="3"
                fontSize="md"
                fontWeight="bold"
                textTransform="uppercase"
                letterSpacing="widest"
                color="gray.900"
                align="center"
                mb="1"
              >
                Marymont Labs
              </Text>
              {/* <Logo /> */}
              <br />
              <Stack spacing="8" flex="1" overflow="auto" pt="8">
                <Stack spacing="1">
                  <NavItem
                    icon={<MdDashboard />}
                    // label="Dashboard"
                    label={username}
                    href="/dashboard"
                  />
                </Stack>
                {
                  linkArray &&
                    linkArray.map((row, idx) => (
                      <NavGroup key={idx} label={row.client}>
                        {row.children &&
                          row.children.map((link, idx) => (
                            <NavItem
                              key={idx}
                              icon={link.icon}
                              label={link.label}
                              href={link.href}
                            />
                          ))}
                      </NavGroup>
                    ))

                  //   <NavGroup label="Admin">
                  //   {/* <NavItem icon={<BiCog />} label={accessRequests && accessRequests.length > 0 ? `Admin (${accessRequests.length})` : `Admin`} href="/admin/0" /> */}
                  //   <NavItem icon={<BiCog />} label="Admin" href="/admin" />
                  //   <NavItem icon={<FiUploadCloud />} label="Upload rate files" href="/raterfileuploads" />
                  // </NavGroup>
                }

                {userInfo.nickname === 'Admin' && (
                  <NavGroup label="Admin">
                    {/* <NavItem icon={<BiCog />} label={accessRequests && accessRequests.length > 0 ? `Admin (${accessRequests.length})` : `Admin`} href="/admin/0" /> */}
                    <NavItem icon={<BiCog />} label="Admin" href="/admin" />
                    <NavItem icon={<BiCog />} label="Article Admin" href="/jjbest/articleadmin/0" />
                    {/* <NavItem
                      icon={<BiCog />}
                      label="Upload rate files"
                      href="/raterfileuploads"
                    /> */}
                  </NavGroup>
                )}

                <NavGroup label="Your Profile">
                  {/* <NavItem 
                      icon={<GrDocumentText />} 
                      // label={
                      //   `${userInfo.attributes.name} ${userInfo.attributes.family_name} quotes`} 
                      // href="/my-quotes" 
                      /> */}

                  {/* <NavItem 
                      icon={<GiSettingsKnobs />} 
                      // label={
                      //   `${userInfo.attributes.name} ${userInfo.attributes.family_name}`} 
                      // href={`/profile/${userInfo[0].userId}`} 
                      /> */}

                  <LogoutNavItem icon={<FiLogOut />} label="Logout" />
                </NavGroup>
              </Stack>
              <Box></Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </div>
  );
};

export default Sidebar;
