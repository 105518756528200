import { Box, Link } from '@chakra-ui/react';

const SocialButton = ({ href, children, ...props }) => {
  return (
    <Link href={href} isExternal>
      <Box
        as="button"
        borderRadius="5px"
        w="8"
        h="8"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="blue.400"
        color="white"
        transition="all 0.2s"
        _hover={{
          bg: 'blackAlpha.500',
        }}
        {...props}
      >
        {children}
      </Box>
    </Link>
  );
};

export default SocialButton;