import React, { useEffect } from 'react';
import JJBestHeroCarousel from '../../../../components/jjbest/JJBestHeroCarousel';
import JJBestBoatContent from '../../../../components/jjbest/JJBestBoatContent';
import LoanCalculator from '../../../../components/jjbest/LoanCalculator';
import JJBestFooter from '../../../../components/jjbest/JJBestFooter';
import JJBestFeatures from '../../../../components/jjbest/JJBestFeatures';
import LandingLayoutJJBest from '../../../../components/jjbest/LandingLayoutJJBest';
import protectedRoute from '../../../../utils/protectedRoute';

const JJBestBoatFinancingNoNav = props => {
  const { userInfo } = props;

  useEffect(() => {}, [userInfo]);

  return (
    <LandingLayoutJJBest>
      <JJBestHeroCarousel />
      <JJBestBoatContent />
      <JJBestFeatures />
      <LoanCalculator />
      <JJBestFooter />
    </LandingLayoutJJBest>
  );
};

export default protectedRoute(JJBestBoatFinancingNoNav);
