import {
  Stack,
  CircularProgress,
  Box,
  Flex,
  Divider,
  Container,
  Heading,
  FormControl,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import LandingLayoutJJBest from '../../../../../components/jjbest/LandingLayoutJJBest';
import JJBestFooter from '../../../../../components/jjbest/JJBestFooter';
import { listArticles, listTags } from '../../../../../graphql/queries';
import BlogPost from '../../../../../components/articles/BlogPost';

const _ = require('lodash');

const SearchByTagname = props => {
  const { userInfo } = props;
  const { tagname } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedTags, setSortedTags] = useState([]);

  useEffect(() => {
    const handleFetchArticles = async () => {
      handleSortedTags();
      if (tagname) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: listArticles,
          });

          let articleData = result.data.listArticles.items;
          let sortedArticleData = _.orderBy(
            articleData,
            ['createdAt'],
            ['asc']
          );
          if (tagname === 'all') {
            setArticles(sortedArticleData);
            setLoading(false);
          } else {
            let resultData = [];
            for (let i = 0; i <= sortedArticleData.length - 1; i++) {
              const articleObj = sortedArticleData[i];
              for (
                let j = 0;
                j <= articleObj.articleTags.items.length - 1;
                j++
              ) {
                const tagObj = articleObj.articleTags.items[j];
                if (tagObj.articleTagName === tagname) {
                  resultData.push(articleObj);
                  break;
                }
              }
            }
            setArticles(resultData);
            setLoading(false);
          }
        } catch (err) {
          console.log('error in fetchArticle: ', err);
        }
      }
    };
    const handleSortedTags = async () => {
      try {
        const client = generateClient({ authMode: 'userPool' });
        let tagResult = await client.graphql({
          query: listTags,
        });
        const result = _.orderBy(
          tagResult.data.listTags.items,
          ['tagName'],
          ['asc']
        );
        setSortedTags(result);
      } catch (err) {
        console.log('error in handleSortedTags: ', err);
      }
    };
    handleFetchArticles();
  }, [userInfo, tagname]);

  return loading ? (
    <CircularProgress />
  ) : (
    <LandingLayoutJJBest>
      <Container py="16" flex="1" maxW={'8xl'}>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '12', lg: '16' }}
          flex="1"
        >
          {/* <Main /> */}
          <Box
            as="main"
            role="main"
            width="full"
            bg="bg.accent.default"
            // bgColor="gray.100"
          >
            <Container py={{ base: '4', md: '6' }}>
              <Stack spacing="10" shouldWrapChildren>
                {/* <BlogPageHeader /> */}
                <Box pt="8" pb="16">
                  <Stack spacing={{ base: '8', md: '10' }} align="center">
                    <Stack spacing={{ base: '4', md: '6' }} textAlign="center">
                      <Stack spacing="4">
                        <Heading size={{ base: 'md', md: 'lg' }}>
                          Articles Featuring the Search Tag: {tagname}
                        </Heading>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>

                <Box maxW="2xl" mx="auto">
                  <Stack spacing="16">
                    {articles.map((article, id) => (
                      <Box bgColor="gray.50" p="5" key={id}>
                        <BlogPost article={article} />
                      </Box>
                    ))}
                    <Divider />
                  </Stack>
                </Box>
              </Stack>
            </Container>
          </Box>

          {/* <Sidebar /> */}
          <Box
            as="aside"
            role="complementary"
            bg="bg.accent.default"
            width={{ base: 'full', lg: 'xl' }}
            alignSelf="start"
            position={{ base: 'unset', lg: 'sticky' }}
            top="36"
            bgColor="gray.50"
          >
            <ArticleTags sortedTags={sortedTags} />
          </Box>
        </Stack>
      </Container>

      <JJBestFooter />
    </LandingLayoutJJBest>
  );
};

const ArticleTags = props => {
  const { sortedTags } = props;
  const navigate = useNavigate();

  useEffect(() => {}, [sortedTags]);

  function handleSearchTagClick(e, tag) {
    e.preventDefault();
    navigate(`/jjbest/resources/searchbytag/${tag.tagName}`);
  }

  return sortedTags === null || undefined ? (
    <CircularProgress />
  ) : (
    <Flex h="full" direction="column" px="4" py="4">
      <Stack spacing={4}>
        <Heading as="h4" size="md">
          All Article Tags
        </Heading>
        <FormControl id="taglist">
          <Wrap width="full" spacing="6">
            {sortedTags.map(tag => {
              return (
                <WrapItem key={tag.id}>
                  <Box
                    as="button"
                    key={tag.id}
                    borderRadius="md"
                    bg="secondary.700"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    px={4}
                    py={1}
                    h={8}
                    onClick={e => handleSearchTagClick(e, tag)}
                  >
                    {tag.tagName}
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </FormControl>
      </Stack>
    </Flex>
  );
};

export default SearchByTagname;
