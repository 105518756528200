import {
  Box,
  Container,
  Stack,
  VStack,
  HStack,
  Text,
  Heading,
} from "@chakra-ui/react";

const BioContent = () => (
  <Container maxW={"8xl"} p="5" bgColor="white">
    <Stack align={["center", "center", "center", "center"]}>
      <HStack pb="5">
      <Heading
        as="h1"
        size="xl"
        lineHeight="short"
        fontWeight="bold"
        color="secondary.600"
        mb="5"
        maxW={{
          base: "unset",
          lg: "800px",
        }}
      >
        About me...
      </Heading>
      </HStack>
      
    </Stack>
    <Box bgColor="primary.100" p="10">
    <VStack paddingTop="20px" spacing="2" alignItems="flex-start" >
      <Text
        textStyle={{
          base: "3xl",
          md: "4xl",
        }}
        fontWeight="medium"
      >
        I love to solve challenging problems whether it&apos;s a next-level Sudoku
        puzzle or the digital transformation of a $750M global insurance provider.
        I also love to constantly learn new skills whether it&apos;s baking a
        Christmas yule log cake or getting AWS certified all via self-study.
        Most of all, I love to build things whether it&apos;s a 10&apos; x 10&apos; heated yurt
        for my teenage daughters or a 30-person team of dynamic digital
        professionals or a revolutionary set of Hagerty Valuation Tools for the
        classic car industry.
      </Text>

      <Text
        textStyle={{
          base: "3xl",
          md: "4xl",
        }}
        fontWeight="medium"
      >
        These are some of the key things that drive me.
      </Text>
      <Text
        textStyle={{
          base: "3xl",
          md: "4xl",
        }}
        fontWeight="medium"
      >
        I have over 25 years of tech / business
        intelligence / insurance / healthcare / automotive / consulting / startup
        experience. I am a very hands-on, innovative tech leader with repeated
        success in the design and launch of leading-edge digital transformation
        and business intelligence solutions across diverse industries.
      </Text>
      <Text
        textStyle={{
          base: "3xl",
          md: "4xl",
        }}
        fontWeight="medium"
      >
        I have a talent for analyzing processes and implementing key continuous
        improvement strategies with highly effective results.
      </Text>
      <Text
        textStyle={{
          base: "3xl",
          md: "4xl",
        }}
        fontWeight="medium"
      >
        I am an expert in providing transformational leadership focused on
        digital strategies aligned with current and emerging needs. I am skilled
        in driving organizational change through strategic restructuring and
        rebuilding.
      </Text>
      <Text
        textStyle={{
          base: "3xl",
          md: "4xl",
        }}
        fontWeight="medium"
      >
        I am recognized as a collaborative communicator capable of cultivating
        strong relationships and building consensus across multiple
        organizational levels to achieve dramatic results. I am energized by
        helping companies leverage digital platforms to achieve their goals.
      </Text>
    </VStack>
    </Box>
  </Container>
);

export default BioContent;
