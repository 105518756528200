import React, { useEffect } from "react";
import JJBestHomeHero from "../../components/jjbest/JJBestHomeHero";
import JJBestContent from "../../components/jjbest/JJBestContent";
import LoanCalculator from "../../components/jjbest/LoanCalculator";
import JJBestFooter from "../../components/jjbest/JJBestFooter";
import JJBestDisclosures from "../../components/jjbest/JJBestDisclosures";
import JJBestTestimonials from "../../components/jjbest/JJBestTestamonials";
import LatestArticles from "../../components/jjbest/LatestArticles";
import JJBestFeatures from "../../components/jjbest/JJBestFeatures";
import ClassicCarExamples from "../../components/jjbest/ClassicCarExamples";
import LandingLayoutJJBest from "../../components/jjbest/LandingLayoutJJBest";
import protectedRoute from "../../utils/protectedRoute";


const JJBestHome = (props) => {
  const { userInfo } = props;
  // const isDesktop = useBreakpointValue({
  //   base: false,
  //   lg: true,
  // });

  useEffect(() => {
  }, [userInfo]);

  return ( <LandingLayoutJJBest>
          <JJBestHomeHero />
          <JJBestContent />
          <JJBestFeatures />
          <LoanCalculator />
          <JJBestTestimonials />
          <ClassicCarExamples />
          <LatestArticles />
          <JJBestFooter />
          <JJBestDisclosures />
        </LandingLayoutJJBest>
     
  );
};

export default protectedRoute(JJBestHome);
