import React, { useEffect, useState } from "react";
import {
  Container,
  Flex,
  Heading,
  Stack,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { fetchUserAttributes  } from 'aws-amplify/auth';
import Sidebar from "../components/pageshell/Sidebar";
import Navbar from "../components/pageshell/Navbar";
import protectedRoute from "../utils/protectedRoute";
import { links } from "../data/_client_navData";
import NavItem from "../widgets/NavItem";

const Dashboad = () => {
  const [userInfo, setUserInfo] = useState({})
  const [linkArray, setLinkArray] = useState([]);
  const [navLinks, setNavLinks] = useState([]);
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {
    async function getUserAttributes () {
      try {
        let attributes = await fetchUserAttributes();
        setUserInfo(attributes)
        handleLinkArray(attributes)
      } catch (err) {
        console.log(err);
      }
    }
    const handleLinkArray = (attributes) => {
        if (attributes.nickname === "Admin") {
          setLinkArray(links);
          handleNavLinks(links);
        } else {
          let linkData = links.filter((link)=>{
            return link.client === attributes.nickname
          })
          setLinkArray(linkData);
          handleNavLinks(linkData);
        }
    };
    const handleNavLinks = (data) => {
      if (data) {
        let outputArray = [];
        for (let i = 0; i <= data.length - 1; i++) {
          for (let j = 0; j <= data[i].children.length - 1; j++) {
            let tempObj = {
              client_label: data[i].client + ": " + data[i].children[j].label,
              href: data[i].children[j].href,
              icon: data[i].children[j].icon,
            };
            outputArray.push(tempObj);
          }
        }
        setNavLinks(outputArray);
      }
    };
    getUserAttributes();
  }, []);

  return userInfo === null || undefined ||
    navLinks === null || undefined || 
    linkArray === null || undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container py="8" flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Stack spacing="1">
              <Heading
                fontWeight="medium"
              >
                Links
              </Heading>
            </Stack>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            {navLinks && (
              <SimpleGrid
                columns={{
                  base: 1,
                  md: 3,
                }}
                gap="6"
              >
                {navLinks.map((link, idx) => (
                  <NavItem key={idx} {...link} />
                ))}
              </SimpleGrid>
            )}
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
}

export default protectedRoute(Dashboad)