import { Container, Heading, Stack, HStack } from '@chakra-ui/react';
import EmployerCard from '../widgets/EmployerCard';
import { stats } from '../data/_employerData';

const JobHistoryContainer = () => (
  <Container maxW={'8xl'} p="5" bgColor="white">
    <Stack align={['center', 'center', 'center', 'center']}>
      <HStack pb="5">
        <Heading
          as="h1"
          size="xl"
          lineHeight="short"
          fontWeight="bold"
          color="secondary.600"
          mb="5"
          maxW={{
            base: 'unset',
            lg: '800px',
          }}
        >
          Some companies where I&apos;ve worked...
        </Heading>
      </HStack>
    </Stack>
    <Stack
      spacing={{
        base: '12',
        md: '16',
      }}
      alignItems="stretch"
    >
      <Stack
        spacing="8"
        direction={{
          base: 'column',
          md: 'row',
        }}
      >
        {stats.map((stat, id) => (
          <EmployerCard key={id} {...stat} />
        ))}
      </Stack>
    </Stack>
  </Container>
);

export default JobHistoryContainer;
