import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginScreen from "../../components/auth/LoginScreen"

const Login = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  useEffect(() => {
  }, [userInfo, navigate]);

  return (<>
      <LoginScreen userInfo={userInfo}/>
    </>
  )
}

export default Login