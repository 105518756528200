import {
  AspectRatio,
  Box,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';

const VehicleCard = props => {
  const { car } = props;
  return (
    <Stack spacing="4">
      <Box position="relative" className="group">
        <AspectRatio ratio={3 / 4}>
          <Image
            src={car.image}
            alt={car.name}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius="md"
          />
        </AspectRatio>
      </Box>
      <Stack spacing="1">
        <HStack justifyContent="space-between">
          <Text color="fg.muted">{car.description}</Text>
        </HStack>
      </Stack>
    </Stack>
  );
};

export default VehicleCard;
