import React from "react";
// import { useRouter } from 'next/router'
import { Box, Image } from "@chakra-ui/react";
// import Image from 'next/image'
// import logo from './ForgeLogoColor.svg'
import logo from '../../assets/photos/JoeNiemerWordmark.jpg'
// import logo from '../../../public/photos/forksedge_blackwordmark.svg'


const Logo = (props) => {
  // const router = useRouter();

  // function handleHomeRedirect() {
  //   router.push('/');
  // }
  return (
    <Box as="button" bg="transparent" px={1} h={1} {...props} 
    // onClick={handleHomeRedirect}
    >
      <Image
        boxSize='100px'
        objectFit='contain'
        src={logo}
        alt='Marymont Labs'
      />
    </Box>
  );
}

export default Logo