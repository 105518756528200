import React from "react";
import { redirect } from "react-router-dom";
import { Box, Img } from "@chakra-ui/react";
import logo from './jjbest-logo.svg'

const Logo = (props) => {
  // const router = useRouter();
  function handleHomeRedirect() {
    redirect('/jjbest/classic-car-financing');
  }
  return (
    <Box as="button" bg="transparent" px={1} h={1} {...props}
    onClick={() => handleHomeRedirect()}
    >
       <Img
          src={logo} 
          alt="J.J. Best"
          layout='intrinsic'
          width={300}
          height={150}
       />
    </Box>
  );
}

export default Logo