import { Container, Text, Heading, Stack, VStack } from '@chakra-ui/react';

const JJBestBoatContent = () => {

  return (
    <Container maxW={'8xl'} p="5">
      <Stack align={['left']}>
        <Heading
          as="h1"
          size="xl"
          lineHeight="short"
          fontWeight="bold"
          color="secondary.600"
          maxW={{
            base: 'unset',
            lg: '800px',
          }}
        >
         Financing for Boats of Any Age
        </Heading>
      </Stack>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Text as="p" fontSize="lg">
        J. J. Best Banc & Co. helps more boating enthusiasts acquire the boat of their dreams! No one else has done it longer or better. Whether it&apos;s a sport fishing boat, motor yacht, sailboat, wooden boat, something new or an 80 year old classic, turn to JJ Best when you can&apos;t stop thinking about driving the dream.
        </Text>
        {/* <Text as="p" fontSize="lg">
        Ut dictum libero gravida hendrerit mollis. Quisque sed tellus in magna eleifend pellentesque a eget mauris. Integer dictum felis sed turpis vestibulum, sed rutrum libero varius. Sed tempus faucibus ultricies. Sed interdum, nisl quis lobortis maximus, ex ligula dapibus erat, sit amet egestas mauris sem vitae elit. Nam ac est dapibus, mollis enim eu, vehicula lorem. Sed diam odio, mattis at ipsum quis, vestibulum accumsan turpis.
        </Text> */}

        
      </VStack>
    </Container>
  );
};

export default JJBestBoatContent;