import * as React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'

export const links = [
  {
    title: 'Lending Products',
    links: [
      {
        label: 'Classic cars',
        href: '/jjbest/classic-car-financing/no-nav',
      },
      {
        label: 'Boats financing',
        href: '/jjbest/boat-financing/no-nav',
      },
      {
        label: 'Airplanes',
        href: '/jjbest/boat-financing/no-nav',
      },
      {
        label: 'RVs',
        href: '/jjbest/boat-financing/no-nav',
      },
      {
        label: 'Rare Coins',
        href: '/jjbest/boat-financing/no-nav',
      },
     
    ],
  },
  {
    title: 'About J.J. Best',
    links: [
      {
        label: 'FAQs',
        href: '/jjbest/classic-car-financing/no-nav',
      },
      {
        label: 'Financing Tips',
        href: '/jjbest/boat-financing/no-nav',
      },
      {
        label: 'Dealer Connect',
        href: '/jjbest/boat-financing/no-nav',
      },
    ],
  },
  {
    title: 'Disclosures',
    links: [
      {
        label: 'Privacy Policy',
        href: '/jjbest/classic-car-financing/no-nav',
      },
      {
        label: 'Electronic Records and Signature Disclosure',
        href: '/jjbest/boat-financing/no-nav',
      },
      {
        label: 'State Licensing',
        href: '/jjbest/boat-financing/no-nav',
      },
    ],
  },
  
]
export const socialLinks = [
  {
    label: 'Facebook',
    icon: <FaFacebook />,
    href: '',
  },
  {
    label: 'Instagram',
    icon: <FaInstagram />,
    href: '',
  },
  {
    label: 'LinkedIn',
    icon: <FaLinkedin />,
    href: '',
  },
  {
    label: 'Twitter',
    icon: <FaTwitter />,
    href: '',
  },
]
export const footerLinks = [
  {
    label: 'Home',
    href: '/dashboard',
  },
  {
    label: 'Classic Car Financing',
    href: '/jjbest/classic-car-financing/no-nav',
  },
  {
    label: 'Boat Financing',
    href: '/jjbest/boat-financing/no-nav',
  },
  {
    label: 'Dealers',
    href: '/dashboard',
  },
]