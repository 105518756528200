import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Textarea,
  Flex,
  Box,
  Input,
  Stack,
  FormLabel,
  Button,
  Divider,
  StackDivider,
  FormControl,
  RadioGroup,
  Radio,
  FormHelperText,
  useBreakpointValue,
} from "@chakra-ui/react";
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Sidebar from "../../../../components/pageshell/Sidebar";
import Navbar from "../../../../components/pageshell/Navbar";
import protectedRoute from "../../../../utils/protectedRoute";
import useStep from "../../../../utils/useStep";
import { steps } from "../../../../data/_articleStepData";
import Step from "../../../../widgets/Step";
import { createArticle } from "../../../../graphql/mutations"
import { validateRequiredField } from "../../../../utils/formValidators";

const AddArticle = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [titleErrorText, setTitleErrorText] = useState("");
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setTitleErrorText("");
    setMessage("");
  };
  const [author, setAuthor] = useState("J.J. Best");
  const [authorErrorText, setAuthorErrorText] = useState("");
  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
    setAuthorErrorText("");
    setMessage("");
  };
  const [synopsis, setSynopsis] = useState("");
  const [synopsisErrorText, setSynopsisErrorText] = useState("");
  const handleSynopsisChange = (event) => {
    setSynopsis(event.target.value);
    setSynopsisErrorText("");
    setMessage("");
  };
  // const [url, setUrl] = useState("");
  // const [urlErrorText, setUrlErrorText] = useState("");
  // const handleUrlChange = (event) => {
  //   setUrl(event.target.value);
  //   setUrlErrorText("");
  //   setMessage("");
  // };
  const [featured, setFeatured] = useState("Yes");
  // const [featuredErrorText, setFeaturedErrorText] = useState("");
  // const handleFeaturedChange = (event) => {
  //   setFeatured(event.target.value);
  //   setFeaturedErrorText("");
  //   setMessage("");
  // };
  const [articleID, setArticleID] = useState([]);

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 0,
  });

  useEffect(() => { 
    setArticleID(uuidv4());
  }, [userInfo]);

  async function handleCreateURLString(thisTitle) {
    const thisTitleString = thisTitle.replace(/[:,?]/g, '')
    const urlArray = thisTitleString.split(" ");
    const urlString = urlArray.join("-");
    return urlString.toLowerCase();
  }

  async function handleCreateArticle() {
    let validationResult = false;
    const urlString = await handleCreateURLString(title);
    let tempArticleObj = {
      id: articleID,
      title: title,
      author: author,
      synopsis: synopsis,
    };
    validationResult = await validateForm(tempArticleObj);
    if (validationResult === false) {
      // do nothing
    } else {
      const articleObj = {
        id: articleID,
        title: title,
        author: author,
        synopsis: synopsis,
        url: urlString,
        isFeatured: featured
      };
      try{
        const client = generateClient({authMode: 'userPool'})
        await client.graphql({
          query: createArticle,
          variables: {input: articleObj},
        })
        setTitle("");
        setSynopsis("");
        navigate(`/jjbest/articleadmin/add-image/${articleID}`)
  
      } catch(err){
        console.log("error in createArticle: ", err);
      }
      
    }
  }

  async function validateForm(data) {
    if ((await validateRequiredField(data.title)) === false) {
      setTitleErrorText("Title is required.");
      document.getElementById("title").focus();
      return false;
    } else if ((await validateRequiredField(data.author)) === false) {
      setAuthorErrorText("Author is required.");
      document.getElementById("author").focus();
      return false;
    } else if ((await validateRequiredField(data.synopsis)) === false) {
      setSynopsisErrorText("Synopsis is required.");
      document.getElementById("synopsis").focus();
      return false;
    } 
    // else if((await validateRequiredField(data.url)) === false) { 
    //   document.getElementById("url").focus();
    // } 
    else {
      const thankYouMessage = "Thank you!";
      setMessage(thankYouMessage);
      return true;
    }
  }

  return userInfo === null || undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

<Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          ></Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container py={{ base: "4", md: "8" }} maxW={"6xl"}>
                <Stack spacing="5">
                  <Divider />
                  <Stack spacing="5" divider={<StackDivider />}>
                    <FormControl id="title">
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        <FormLabel variant="inline">Title</FormLabel>

                        <Input
                          type="text"
                          id="title"
                          size="md"
                          bgColor="white"
                          onChange={(event) => handleTitleChange(event)}
                          value={title}
                          name="title"
                          textAlign="left"
                          maxW={{ md: "3xl" }}
                        />
                      </Stack>
                      <Text fontSize="sm" color="secondary.600">
                        {titleErrorText}
                      </Text>
                    </FormControl>
                    <FormControl id="author">
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        <FormLabel variant="inline">Author</FormLabel>
                        <Input
                          type="text"
                          id="author"
                          size="md"
                          bgColor="white"
                          onChange={(event) => handleAuthorChange(event)}
                          value={author}
                          name="author"
                          textAlign="left"
                          maxW={{ md: "3xl" }}
                        />
                      </Stack>
                      <Text fontSize="sm" color="secondary.600">
                        {authorErrorText}
                      </Text>
                    </FormControl>
                    <FormControl id="synopsis">
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        <Box>
                          <FormLabel variant="inline">Synopsis</FormLabel>
                          <FormHelperText mt="0" color="muted">
                            Write a short synopsis of the article
                          </FormHelperText>
                        </Box>
                        <Textarea
                          maxW={{ md: "3xl" }}
                          id="synopsis"
                          rows={5}
                          resize="none"
                          onChange={(event) => handleSynopsisChange(event)}
                          value={synopsis}
                        />
                      </Stack>
                      <Text fontSize="sm" color="secondary.600">
                        {synopsisErrorText}
                      </Text>
                    </FormControl>
                    
                    <FormControl id="featured">
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        <Box>
                          <FormLabel variant="inline">Is this a featured article?</FormLabel>
                          <FormHelperText mt="0" color="muted">
                            This will populate the featured articles component
                          </FormHelperText>
                        </Box>
                        
                        <RadioGroup
                          id="featured"
                          onChange={setFeatured}
                          value={featured}
                          defaultValue="Yes"
                        >
                          <Stack direction="row">
                            <Radio
                              id="Yes"
                              name="featured"
                              value="Yes"
                              borderColor="primary.900"
                            >
                              Yes
                            </Radio>
                            <Radio
                              id="No"
                              name="featured"
                              value="No"
                              borderColor="primary.900"
                            >
                              No
                            </Radio>
                          </Stack>
                        </RadioGroup>
                        </Stack>
                      </FormControl>

                    <Flex direction="row-reverse">
                      <Button
                        mt="8"
                        mb="8"
                        bgColor="secondary.600"
                        colorScheme="blue"
                        px="8"
                        rounded="full"
                        size="md"
                        fontSize="md"
                        fontWeight="bold"
                        type="submit"
                        onClick={handleCreateArticle}
                      >
                        Add Article
                      </Button>
                    </Flex>
                  </Stack>
                </Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(AddArticle);
