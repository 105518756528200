import {
  Container,
  Box,
  Flex,
  SimpleGrid,
  Divider,
  Wrap,
  WrapItem,
  Link,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import SocialButton from '../../widgets/SocialButton';
// import FooterLogo from './FooterLogo2'
import { socialLinks, footerLinks } from "./_footerData";

const Footer = () => (
  <Container maxW={"9xl"} p="5" bg="primary.900" color="white" mx="auto">
    <Flex
      direction={{
        base: "column",
        lg: "row",
      }}
      justify="space-between"
      pb="1"
      align="flex-start"
      id="top"
    >
      <Box
        // paddingEnd="12"
        // mb={{
        //   base: "10",
        //   lg: 0,
        // }}
      >
        {/* <FooterLogo color="white" h="6" /> */}
        
        <HStack spacing="4" mt="8" as="ul">
        <Text color="white" fontWeight="bold">Let&apos;s Connect</Text>
          {socialLinks.map((link, idx) => (
            <SocialButton key={idx} href={link.href}>
              <Box srOnly>{link.label}</Box>
              {link.icon}
            </SocialButton>
          ))}
        </HStack>
      </Box>
      <SimpleGrid
        w="full"
        maxW={{
          base: "unset",
          lg: "4xl",
        }}
        columns={{
          base: 3,
          lg: 5,
        }}
        spacing={{
          base: "8",
          md: "4",
        }}
        fontSize="sm"
      >
        {/* {links.map((group, idx) => (
          <Box key={idx}>
            <Text fontWeight="bold" mb="4">
              {group.title}
            </Text>
            <Stack as="ul" listStyleType="none">
              {group.links.map((link, idx) => (
                <Box as="li" key={idx}>
                  <Box
                    as="a"
                    href={link.href}
                    _hover={{
                      textDecor: 'underline',
                    }}
                  >
                    {link.label}
                    {link.badge && (
                      <Box as="span" marginStart="2">
                        {link.badge}
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>
        ))} */}
      </SimpleGrid>
    </Flex>
    <Divider my="10" borderColor="white" />
    <Flex
      direction={{
        base: "column-reverse",
        lg: "row",
      }}
      align={{
        base: "flex-start",
        lg: "center",
      }}
      justify="space-between"
      fontSize="sm"
    >
      <Wrap
        id="bottom"
        spacing={{
          base: "4",
          lg: "8",
        }}
        mt={{
          base: "4",
          lg: "0",
        }}
      >
        <WrapItem>
          <Box color="white">
            &copy; {new Date().getFullYear()} Marymont Labs, LLC All rights
            reserved.
          </Box>
        </WrapItem>
        {footerLinks.map((link, idx) => (
          <WrapItem key={idx}>
            <Link href={link.href}>
              <Text>{link.label}</Text>
            </Link>
          </WrapItem>
        ))}
      </Wrap>
      {/**COPYRIGHT */}
    </Flex>
  </Container>
);

export default Footer;
