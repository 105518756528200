import { Circle, Icon } from '@chakra-ui/react'
import { HiCheck } from 'react-icons/hi'

const StepCircle = (props) => {
  const { isCompleted, isActive, ...rest } = props
  return (
    <div {...rest}>
    <Circle
      size="8"
      bg={isCompleted ? 'primary.900' : 'white'}
      borderWidth={isCompleted ? '0' : '2px'}
      borderColor={isActive ? 'primary.900' : 'gray.300'}
      //{...rest}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color="white" boxSize="5" />
      ) : (
        <Circle bg={isActive ? 'primary.900' : 'gray.300'} size="3" />
      )}
    </Circle>
    </div>
  )
}

export default StepCircle