import { Flex } from "@chakra-ui/react"
import NavbarJJBest from './NavbarJJBest'

const LandingLayout = (props) => {
  return (
    <Flex
      direction="column"
      align="center"
      // maxW={{ xl: "1200px" }}
      p="1"
      m="0 auto"
      {...props}
    >
      <NavbarJJBest />
      {props.children}
    </Flex>
  );
}

export default LandingLayout