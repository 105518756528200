import { useEffect, useState } from 'react';
import {
  Container,
  CircularProgress,
  Flex,
  Box,
  Input,
  Stack,
  VStack,
  FormLabel,
  StackDivider,
  FormControl,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadData } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';
import { createArticleImage } from '../../../../../graphql/mutations';
import useStep from '../../../../../utils/useStep';
import { steps } from '../../../../../data/_articleStepData';
import Step from '../../../../../widgets/Step';
import Sidebar from '../../../../../components/pageshell/Sidebar';
import Navbar from '../../../../../components/pageshell/Navbar';
import config from '../../../../../aws-exports';

const AddImage = props => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  // const [file, setFile] = useState();

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 1,
  });

  useEffect(() => {
    setLoading(false);
  }, [userInfo]);

  async function handleAddImage(image) {

    const articleImageObj = {
      id: uuidv4(),
      articleID: image.articleID,
      articleImageFile: image.articleImageFile,
      articleImageObject: image.articleImageObject,
    };
    try {
      const client = generateClient({ authMode: 'userPool' });
      await client.graphql({
        query: createArticleImage,
        variables: { input: articleImageObj },
      });
      navigate(`/jjbest/articleadmin/add-content-md/${id}`);
    } catch (err) {
      console.log('error in createApplication: ', err);
    }
  }

  async function handleImageChange(e) {
    setLoading(true);
    const file = e.target.files[0];
    const prefix = 'article';
    const uploadFileName = `articles/${prefix}-${id}-${Date.now()}.jpeg`;

    try {
      // const result = 
      await uploadData({
        // path: 'public/album/2024/1.jpg', 
        path: `public/articles/${prefix}-${id}-${Date.now()}.jpeg`,
        // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
        data: file,
        // options: {
        //   onProgress // Optional progress callback.
        // }
      }).result;

      const image = {
        articleID: id,
        articleImageFile: uploadFileName,
        articleImageObject: {
          bucket: config.aws_user_files_s3_bucket,
          region: config.aws_user_files_s3_bucket_region,
          key: `${uploadFileName}`,
        },
      };
      handleAddImage(image);
      setLoading(false);
      // navigate(`/jjbest/articleadmin/add-content/0+${id}`);
      navigate(`/jjbest/articleadmin/add-content-md/0+${id}`);
    } catch (error) {
      console.log('Error upload image: ', error);
    }
  }

  return loading || userInfo === null || userInfo === undefined ? (
    <CircularProgress />
  ) : (
    <Flex
      as="section"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={'8xl'}>
        <Stack
          spacing={{
            base: '8',
            lg: '6',
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              lg: 'row',
            }}
            justify="space-between"
            align={{
              base: 'start',
              lg: 'center',
            }}
          ></Stack>
          <Stack
            spacing={{
              base: '5',
              lg: '6',
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={'6xl'}>
                <Stack
                  spacing="0"
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container py={{ base: '4', md: '8' }} maxW={'6xl'}>
                <Stack spacing="5">
                  {/* <Divider /> */}
                  <Stack spacing="5" divider={<StackDivider />}>
                    <FormControl id="picture">
                      <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={{ base: '1.5', md: '8' }}
                        justify="space-between"
                      >
                        <FormLabel variant="inline" size="lg">
                          Add An Image
                        </FormLabel>
                        <Stack shouldWrapChildren spacing="4">
                          <VStack width="full" spacing="6">
                            <FormControl id="sectionHeader">
                              <Input
                                type="file"
                                p="2"
                                m="2"
                                variant="unstyled"
                                onChange={e => handleImageChange(e)}
                              />
                            </FormControl>
                          </VStack>
                        </Stack>
                      </Stack>
                    </FormControl>
                  </Stack>
                </Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default AddImage;
