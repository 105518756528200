import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  Link,
} from '@chakra-ui/react';
import { HiChevronRight } from 'react-icons/hi';
import { FaLinkedin } from 'react-icons/fa';
import homeimage from '../../assets/photos/home2.jpg'

const HomeHero = () => {
  return (
    <Box bg="gray.800" as="section" minH="140px" position="relative" w={'full'}>
      <Box py="40" position="relative" zIndex={1}>
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          color="white"
        >
          <Box maxW="xl">
            <Heading as="h1" size="3xl" fontWeight="extrabold">
              Welcome to Marymont Labs
            </Heading>
            <Text fontSize={{ md: '2xl' }} mt="4" maxW="lg">
              I am Joe Niemer. I am a hands-on tech leader and consultant with
              20+ years of digital transformation and business intelligence
              innovation
            </Text>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              mt="10"
              spacing="4"
            >
              <Link href="https://www.linkedin.com/in/joe-niemer/" isExternal>
                <Button
                  leftIcon={<FaLinkedin />}
                  bgColor="cta.100"
                  colorScheme="blue"
                  px="8"
                  rounded="full"
                  size="lg"
                  fontSize="md"
                  fontWeight="bold"
                >
                  Let&apos;s connect
                </Button>
              </Link>
              <Link href="/auth/login">
                <HStack
                  // as="a"
                  transition="background 0.2s"
                  justify={{ base: 'center', md: 'flex-start' }}
                  href="#"
                  color="white"
                  rounded="full"
                  fontWeight="bold"
                  px="6"
                  py="3"
                  _hover={{ bg: 'whiteAlpha.300' }}
                >
                  <span>Login</span>
                  <HiChevronRight />
                </HStack>
              </Link>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Image
            // src="https://images.unsplash.com/photo-1590650153855-d9e808231d41?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2250&q=80"
            src={homeimage}
            alt="Marymont Labs"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="top bottom"
            position="absolute"
          />
          <Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
        </Box>
      </Flex>
    </Box>
  );
};

export default HomeHero;
