import {
  HStack,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import { features } from "../../data/_jjbest_featureData";

const JJBestFeatures = () => (
  <Container maxW={"8xl"} p="5" bgColor="gray.50">
    <Stack spacing={{ base: "12", md: "16" }}>
      <Stack spacing={{ base: "4", md: "5" }} maxW="3xl">
        <Stack spacing="3">
          {/* <Text
            fontSize={{ base: "sm", md: "md" }}
            fontWeight="semibold"
            color="accent"
          >
            Features
          </Text> */}
          <Heading size={{ base: "sm", md: "md" }}>
           Why Choose J.J. Best Banc?
          </Heading>
        </Stack>
        <Text color="fg.muted" fontSize={{ base: "lg", md: "xl" }}>
        As the nation's oldest and most experienced collector car and boat lender, we deeply understand the collector market and we always look out for your best interests.
        </Text>
      </Stack>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2 }}
        columnGap={8}
        rowGap={{ base: 10, md: 16 }}
      >
        {features.map((feature) => (
          <HStack key={feature.name} spacing={{ base: "4", md: "5" }}>
            <Square
              size={{ base: "10", md: "12" }}
              bg="accent"
              color="fg.inverted"
              borderRadius="lg"
            >
              <Icon
                as={feature.icon}
                boxSize={{ base: "10", md: "12" }}
                color="secondary.700"
              />
            </Square>
            <Stack spacing={{ base: "1", md: "2" }} flex="1">
              <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="medium">
                {feature.name}
              </Text>
              <Text color="fg.muted">{feature.description}</Text>
            </Stack>
          </HStack>
        ))}
      </SimpleGrid>
    </Stack>
  </Container>
);

export default JJBestFeatures;
