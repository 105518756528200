import { Box, HStack } from '@chakra-ui/react'
import * as React from 'react'
import { BsCaretRightFill } from 'react-icons/bs'

const NavItem = (props) => {
  const { active, icon, children, label, endElement, href } = props
  return (
    <HStack
      as="a"
      href={href}
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? 'primary.900' : undefined}
      _hover={{
        bg: 'gray.200',
      }}
      _active={{
        bg: 'gray.200',
      }}
    >
      <Box fontSize="lg" color="secondary.700">
        {icon}
      </Box>
      <Box flex="1" fontWeight="bold" color="primary.900">
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  )
}

export default NavItem