import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Container,
  Divider,
  Textarea,
  Stack,
  StackDivider,
  FormControl,
  Button,
  Wrap,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { v4 as uuidv4 } from "uuid";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { createArticleTag } from "../../../../../graphql/mutations";
import {
  listTags,
} from "../../../../../graphql/queries";
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_articleStepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";

const _ = require("lodash");

const AddArticleTags = (props) => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  // const [article, setArticle] = useState([]);
  // const [thisArticleSectionData, setThisArticleSectionData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [sortedTags, setSortedTags] = useState([]);

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 4,
  });

  useEffect(() => {
    // const handleFetchArticle = async () => {
    //   if (id) {
    //     try{
    //       const client = generateClient({authMode: 'userPool'})
    //       let result = await client.graphql({
    //         query: getArticle,
    //         variables: { id: id }
    //       })
    //       setArticle(result.data.getArticle);
    //       setLoading(false);
    //       // if (result.data.getArticle.articleSections.items.length === 0) {
    //       //   const sectionResult = await article.articleSections.items;
    //       //   setThisArticleSectionData(sectionResult);
    //       //   setLoading(false);
    //       // }
         
    //     } catch(err){
    //       console.log("error in fetchArticle: ", err);
    //     }

        
    //   }
    // };
    
    const handleSortedTags = async () => {
      try {
        const client = generateClient({ authMode: 'userPool' });
        let tagResult = await client.graphql({
          query: listTags,
        });
        const result = _.orderBy(
          tagResult.data.listTags.items,
          ['tagName'],
          ['asc']
        );
        setSortedTags(result);
      } catch (err) {
        console.log('error in handleSortedTags: ', err);
      }
    };
    // handleFetchArticle();
    handleSortedTags();
  }, [userInfo, id]);

  async function handleChipClick(tagName) {
    const selectedIndex = selected.indexOf(tagName);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, tagName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  }

  async function handleAddArticleTags() {
    for (let i = 0; i <= selected.length - 1; i++) {
      let articleTagForm = {};
      articleTagForm = {
        articleID: id,
        articleTagName: selected[i],
      };
      const articleTag = { ...articleTagForm, id: uuidv4() };
      try {
        const client = generateClient({authMode: 'userPool'})
        await client.graphql({
          query: createArticleTag,
          variables: {input: articleTag},
        })
      } catch (err) {
        console.log("error createArticleTag: ", err);
      }
    }
    setSelected([]);
  }

  async function handleResetNav() {
    navigate("/jjbest/articleadmin/0");
  }

  return userInfo === null || userInfo === undefined || sortedTags === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          ></Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container py={{ base: "4", md: "8" }} maxW={"6xl"}>
                <Stack spacing="5">
                  <Divider />
                  <Stack spacing="5" divider={<StackDivider />}>
                    <FormControl id="bio">
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "1.5", md: "8" }}
                        justify="space-between"
                      >
                        {/* <Box>
                          <FormLabel variant="inline">Selected Tags</FormLabel>
                          <FormHelperText mt="0" color="muted">
                            Write section content
                          </FormHelperText>
                        </Box> */}
                        <Textarea
                          maxW={{ md: "3xl" }}
                          id="selected"
                          rows={5}
                          resize="none"
                          // onChange={(event) => handleSectionContentChange(event)}
                          value={selected}
                          readOnly
                        />
                      </Stack>
                    </FormControl>
                    <FormControl id="taglist">
                      <Wrap width="full" spacing="3">
                        {sortedTags.map((tag) => (
                          <Wrap key={tag.id}>
                            <WrapItem>
                              <Box
                                borderRadius="md"
                                bg="tomato"
                                color="white"
                                px={4}
                                h={8}
                                py={1}
                                onClick={() => handleChipClick(tag.tagName)}
                              >
                                {tag.tagName}
                              </Box>
                            </WrapItem>
                          </Wrap>
                        ))}
                      </Wrap>
                    </FormControl>

                    <Flex direction="row-reverse">
                      <Button
                        mt="8"
                        mb="8"
                        bgColor="secondary.600"
                        colorScheme="blue"
                        px="8"
                        rounded="full"
                        size="md"
                        fontSize="md"
                        fontWeight="bold"
                        type="submit"
                        onClick={() => handleAddArticleTags()}
                      >
                        Add Tags to Article
                      </Button>
                    </Flex>
                    <Stack
                      direction={{ base: "column", md: "row" }}
                      spacing={{ base: "5", md: "6" }}
                      justify="space-between"
                      mt="5"
                    >
                      <Box>
                        <Button
                          variant="link"
                          colorScheme="blue"
                          alignSelf="start"
                          leftIcon={<FiArrowLeft fontSize="1.25rem" />}
                          //   onClick={() => handleArticleTagNav()}
                        >
                          Back
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="link"
                          colorScheme="blue"
                          alignSelf="start"
                          leftIcon={<FiArrowRight fontSize="1.25rem" />}
                          onClick={() => handleResetNav()}
                        >
                          Reset
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default AddArticleTags;
