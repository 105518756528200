import { FaCar } from "react-icons/fa";
import { BiTaxi } from 'react-icons/bi';
import { FaFileContract, FaLock  } from "react-icons/fa";
import { MdDirectionsBoat } from "react-icons/md";

export const links = [
  {
    label: 'Forge',
    client: 'Forge',
    href: '/',
    children: [
      {
        label: 'Taxi Insurance',
        href: '/forge/insurance/taxi-insurance',
        icon: <BiTaxi />
      },
      {
        label: 'Get an insurance quote',
        href: '/get-a-quote/zip-code',
        icon: <FaFileContract />
      },
      {
        label: 'All insurance quotes',
        href: '/forge/direct-quotes',
        icon: <FaFileContract />
      }
  ]
  },
  {
    label: 'JJ Best',
    client: 'JJ Best',
    href: '/jjbest',
    // icon: <FaCar />,
    children: [
      {
        label: 'Classic car financing',
        href: '/jjbest/classic-car-financing',
        icon: <FaCar />
      },
      {
        label: 'Boat financing',
        href: '/jjbest/boat-financing',
        icon: <MdDirectionsBoat />
      },
      {
        label: 'Get pre-approved',
        href: '/jjbest/application/app-start',
        icon: <FaLock />
      },
      {
        label: 'All loan applications',
        href: '/jjbest/direct-apps',
        icon: <FaFileContract />
      }
  ]
  },
  // {
  //   label: 'Thompson Retractor',
  //   client: 'Thompson Retractor',
  //   href: '/thompson',
  //   // icon: <FaCar />,
  //   children: [
  //     {
  //       label: 'Thompson Label 1',
  //       href: '/jjbest/classic-car-financing',
  //       icon: <GrContract />
  //     },
  //     {
  //       label: 'Thomposon Label 2',
  //       href: '/jjbest/application/app-start',
  //       icon: <GrDocumentText />
  //     }
  // ]
  // }
]