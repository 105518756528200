import {
  Box,
  Container,
  Stack,
  Text,
  AspectRatio,
  HStack,
  Img,
  Link,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';
// import forge from '../../public/photos/ForgeTaxiWebsite.jpg'

const WorkHistoryCard = (props) => {
  const { category, description, image, title, url } = props;
  const color1 = useColorModeValue('sm', 'sm-dark');

  return (
    
      <Container boxShadow={color1} flex="1" maxW={'3xl'}>
        <Link href={url} isExternal>
      <Box position="relative">
        <AspectRatio minHeight='400px' ratio={16 / 9}>
          <Img
            src={image}
            alt="Joe Niemer"
            draggable="false"
            fallback={<Skeleton />}
            // borderRadius={{
            //   base: 'md',
            //   md: 'xl',
            // }}
          />
        </AspectRatio>
       
      </Box>
      <Stack>
        <Stack spacing="1">
          <Text fontWeight="medium" fontSize='lg' color={useColorModeValue('gray.700', 'gray.400')}>
            {category} || {title}
          </Text>
     
        </Stack>
        <HStack>
          
          <Text fontSize="md" color={useColorModeValue('gray.600', 'gray.400')}>
          {description}
          </Text>
        </HStack>
      </Stack>
      </Link>
      </Container>
  );
};

export default WorkHistoryCard;
