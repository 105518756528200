import { useEffect, useState } from "react";
import {
  Container,
  Box,
  StackDivider,
  Flex,
  Stack,
  Button,
  Heading,
  Icon,
  Square,
  Text,
  Link,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { FiArrowRight } from "react-icons/fi";
import { FaRegSmile, FaRegSmileWink, FaRegSmileBeam } from "react-icons/fa";
import { getApplication } from "../../../../../graphql/queries"
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_jjbest_stepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";
import { formatter } from "../../../../../utils/currencyFormatter";
import QuoteResultItem from "../../../../../components/jjbest/QuoteResultItem";
import { features } from "../../../../../data/_jjbest_featureData";
import protectedRoute from "../../../../../utils/protectedRoute"

const ApplicationResult = (props) => {
  const { userInfo } = props;
  const { id } = useParams();
  const [application, setApplication] = useState([])
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [consent, setConsent] = useState(false);
  const [gbbFeatures, setGbbFeatures] = useState([]);
  const color1 = useColorModeValue("sm", "sm-dark");

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 4,
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }
    }
    handleFetchApplication()
  }, [userInfo, id]);

  async function handleCalculateApplication() {
    let goodData = [];
    let betterData = [];
    let bestData = [];
    if (application) {
      for (let i = 0; i <= application.applicationVersions.items.length - 1; i++) {
        let item = application.applicationVersions.items[i];
        if (item.optionName === "Good") {
          goodData = item;
        } else if (item.optionName === "Better") {
          betterData = item;
        } else {
          bestData = item;
        }
      }

      const features = [
        {
          featureId: 1,
          description: "Good for those who want to own it quicker",
          icon: FaRegSmile,
          optionName: goodData.optionName,
          applicationId: application.id,
          monthlyPayment: goodData.monthlyPayment,
          totalInterest: goodData.totalInterest,
          totalPayments: goodData.totalPayments,
          loanTerm: goodData.loanTerm,
          interestRate: goodData.interestRate,
        },
        {
          featureId: 2,
          description:
            "A better monthly payment with a manageable payoff time",
          icon: FaRegSmileWink,
          optionName: betterData.optionName,
          applicationId: application.id,
          monthlyPayment: betterData.monthlyPayment,
          totalInterest: betterData.totalInterest,
          totalPayments: betterData.totalPayments,
          loanTerm: betterData.loanTerm,
          interestRate: betterData.interestRate,
        },
        {
          featureId: 3,
          description: "Lowest monthly payments. Period.",
          icon: FaRegSmileBeam,
          optionName: bestData.optionName,
          applicationId: application.id,
          monthlyPayment: bestData.monthlyPayment,
          totalInterest: bestData.totalInterest,
          totalPayments: bestData.totalPayments,
          loanTerm: bestData.loanTerm,
          interestRate: bestData.interestRate,
        },
      ];
      setGbbFeatures(features);
      setConsent(true);
    }
  }

  return userInfo === null || userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container
                // spacing="5"
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"6xl"}
              >
                <Box as="section" bg="bg-surface">
                  {consent === false && (
                    <IndicativeConsentScreen application={application} />
                  )}
                  {consent === false && (
                    <Box
                      as="section"
                      py={{
                        base: "4",
                        md: "8",
                      }}
                    >
                      <Box
                        bg="bg-surface"
                        boxShadow={color1}
                        borderRadius="lg"
                        p={{
                          base: "4",
                          md: "6",
                        }}
                        bgColor="gray.50"
                      >
                        <Stack
                          justify="center"
                          direction={{ base: "column", md: "row" }}
                          spacing="5"
                        >
                          <Button
                            size="lg"
                            bgColor="secondary.700"
                            color="white"
                            onClick={handleCalculateApplication}
                          >
                            Show all quotes
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  )}
                  {consent === true && (
                    <IndicativeQuoteScreen
                      userInfo={userInfo}
                      gbbFeatures={gbbFeatures}
                      application={application}
                    />
                  )}

                  <Container
                    py={{
                      base: "6",
                      md: "12",
                    }}
                    maxW={"6xl"}
                  >
                    <Stack spacing="5" divider={<StackDivider />}>
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        spacing={{ base: "5", md: "6" }}
                        justify="space-between"
                        mt="5"
                      >
                        <Stack spacing="1">
                          {/* <Link
                            href={`/get-a-quote/quote-cart/yes/0/${id}`}
                            passHref
                          >
                            <Button
                              variant="link"
                              colorScheme="blue"
                              alignSelf="start"
                              leftIcon={<FiArrowLeft fontSize="1.25rem" />}
                            >
                              Back
                            </Button>
                          </Link> */}
                        </Stack>
                      </Stack>
                    </Stack>
                  </Container>
                </Box>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

const IndicativeConsentScreen = (props) => {
  const { application } = props;
  return (
    <Container maxW={"5xl"}>
      <Stack
        spacing={{
          base: "12",
          md: "16",
        }}
      >
        <Stack
          spacing={{
            base: "4",
            md: "5",
          }}
          maxW="3xl"
        >
          <Stack spacing="3">
            <Heading color="primary.900">
              Nice job {application.firstName}!
            </Heading>
          </Stack>
          <Text
            color="muted"
            fontSize={{
              base: "lg",
              md: "xl",
            }}
          >
            We created 3 indicative or estimated quotes based on the information
            you provided.
          </Text>
        </Stack>
      </Stack>
    </Container>
  );
};

const IndicativeQuoteScreen = (props) => {
  const { gbbFeatures, application } = props;
  return (
    <Container maxW={"6xl"}>
      <Stack
        spacing={{
          base: "12",
          md: "16",
        }}
      >
        <Stack
          spacing={{
            base: "4",
            md: "5",
          }}
          maxW="3xl"
        >
          <Stack spacing="3">
            <Heading color="primary.900">
              Nice job {application.firstName}!
            </Heading>
          </Stack>
          <Text
            color="muted"
            fontSize={{
              base: "lg",
              md: "xl",
            }}
          >
            Here are 3 indicative quotes for your {application.vehicleYear} {application.make} {application.model}
          </Text>
        </Stack>
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          spacing={{
            base: "12",
            lg: "16",
          }}
        >
          <Stack
            spacing={{
              base: "4",
              md: "8",
            }}
            maxW="xl"
            justify="center"
            width="full"
          >
            {gbbFeatures.map((feature, idx) => (
              <Stack key={idx} spacing="4" direction="row">
                <Square
                  size={{
                    base: "20",
                    md: "24",
                  }}
                  bg="accent"
                  color="inverted"
                  borderRadius="lg"
                >
                  <Icon
                    color="secondary.700"
                    as={feature.icon}
                    boxSize={{
                      base: "8",
                      md: "10",
                    }}
                  />
                </Square>
                <Stack
                  spacing={{
                    base: "1",
                    md: "1",
                  }}
                  // pt={{
                  //   base: '1.5',
                  //   md: '2.5',
                  // }}
                >
                  <Stack
                    spacing={{
                      base: "1",
                      md: "2",
                    }}
                  >
                    <Heading size="md">
                      {formatter.format(feature.monthlyPayment)} Monthly Payment
                    </Heading>

                    <Text
                      fontSize={{
                        base: "lg",
                        md: "xl",
                      }}
                      fontWeight="medium"
                    >
                      {feature.description}
                    </Text>
                    <Text color="muted">
                      {feature.interestRate}% for {feature.loanTerm} years
                    </Text>
                    <Text color="muted">
                      Total Interest: {formatter.format(feature.totalInterest)}{" "}
                      Total Payments: {formatter.format(feature.totalPayments)}
                    </Text>
                  </Stack>
                  <Link
                    href={`/jjbest/application/contact-us/${feature.applicationId}`}
                  >
                    <Button
                      variant="link"
                      colorScheme="blue"
                      rightIcon={<FiArrowRight fontSize="1.25rem" />}
                      alignSelf="start"
                    >
                      Customize
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Box width="full" overflow="hidden">
            <Stack
              spacing={{ base: "6", md: "10" }}
              align="center"
              p="2"
              bgColor="gray.50"
            >
              <Heading size="md" color="secondary.700">Don&apos;t Just Admire It. Own it.</Heading>
              {/* <Text
                align="center"
                fontSize={{
                  base: "lg",
                  md: "xl",
                }}
                fontWeight="medium"
              >
                We are the nation&apos;s oldest, largest and most experienced classic
                and collector car lender.
              </Text> */}
              <Stack spacing="8">
                <Stack spacing="6">
                  {features.slice(0, 3).map((feature, idx) => (
                    <div key={idx}>
                      <QuoteResultItem feature={feature} />
                      {/* <Divider /> */}
                    </div>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default protectedRoute(ApplicationResult);
