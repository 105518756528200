import {
  Box,
  Button,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
// import { v4 as uuidv4 } from "uuid";
// import { createNewUser, addUserToGroup } from "../../utils/clientActions";

const Scratchpad = () => {
  // const { userInfo } = props;
  const color1 = useColorModeValue("sm", "sm-dark");

  useEffect(() => {}, []);

  async function handleApiCall() {
    console.log("inside handleApiCall")
    
    // const client = generateClient({authMode: 'userPool'})
    // let result = await client.graphql({
    //   query: listApplications,
    // })
      try {
        const testData = {
          'application': "this is a hopeful note"
        }
        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        console.log("authToken: ", authToken)
          // const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
          const { body } = await post({
            apiName: 'joe-niemer-backend-v2',
            path: '/rate',
            options: {
              body: testData,
              headers: {
                Authorization: authToken
              },
            }
          }).response;
        const data = await body.json()
        console.log("data: ", data)
        // return await response.body.json();
      } catch (e) {
        console.log('GET call failed: ', JSON.parse(e.response.body));
      }

  }

  return (
    <Box
      as="form"
      bg="bg-surface"
      boxShadow={color1}
      borderRadius="lg"
      border="2px solid currentColor"
      borderColor="gray.200"
      //{...props}
    >
      <Container py="8" flex="1" maxW={"3xl"}>
        <Stack spacing="1">
          <Text fontSize="lg" fontWeight="medium">
            Scratchpad
          </Text>
        </Stack>
        <Stack
          spacing="5"
          px={{
            base: "4",
            md: "6",
          }}
          py={{
            base: "5",
            md: "6",
          }}
        >
        <Text>Some Text</Text>
        <Button onClick={handleApiCall}>Some Button</Button>
            
        </Stack>
        
      </Container>
    </Box>
  );
};

export default Scratchpad;
