import { Container, Text, Heading, Stack, VStack } from '@chakra-ui/react';

const JJBestContent = () => {

  return (
    <Container maxW={'8xl'} p="5">
      <Stack align={['left']}>
        <Heading
          as="h1"
          size="xl"
          lineHeight="short"
          fontWeight="bold"
          color="secondary.600"
          maxW={{
            base: 'unset',
            lg: '800px',
          }}
        >
          Financing For Classic and Exotic Cars
        </Heading>
      </Stack>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Text as="p" fontSize="lg">
        J. J. Best Banc & Co. helps more car enthusiasts acquire the collector car of their dreams! No one else has done it longer or better. Lovers of antiques, classics, exotics, hot rods, kit, muscle and sports cars and trucks all turn to J.J. Best when they just can&apos;t stop thinking about that sweet ride they desire.
        </Text>
        {/* <Text as="p" fontSize="lg">
        Ut dictum libero gravida hendrerit mollis. Quisque sed tellus in magna eleifend pellentesque a eget mauris. Integer dictum felis sed turpis vestibulum, sed rutrum libero varius. Sed tempus faucibus ultricies. Sed interdum, nisl quis lobortis maximus, ex ligula dapibus erat, sit amet egestas mauris sem vitae elit. Nam ac est dapibus, mollis enim eu, vehicula lorem. Sed diam odio, mattis at ipsum quis, vestibulum accumsan turpis.
        </Text> */}

        
      </VStack>
    </Container>
  );
};

export default JJBestContent;