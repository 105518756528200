import { useEffect, useState } from 'react';
import {
  Container,
  Text,
  Flex,
  Box,
  Stack,
  IconButton,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import {
  updateApplication,
  createApplicationVersion,
} from '../../../../../graphql/mutations';
import { getApplication } from '../../../../../graphql/queries';
import useStep from '../../../../../utils/useStep';
import { steps } from '../../../../../data/_jjbest_stepData';
import Step from '../../../../../widgets/Step';
import Sidebar from '../../../../../components/pageshell/Sidebar';
import Navbar from '../../../../../components/pageshell/Navbar';
import { gbb_options } from '../../../../../data/_jjbest_GBB';
import { calculateLoan } from '../../../../../utils/loanGbb';
import protectedRoute from '../../../../../utils/protectedRoute';

const SubmitApplication = props => {
  const { userInfo } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const [application, setApplication] = useState([]);
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [disclaimerCheck, setDisclaimerCheck] = useState('');
  const [insuranceInterest, setInsuranceInterest] = useState('');

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 4,
  });

  useEffect(() => {
    async function handleFetchApplication() {
      if (userInfo) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id },
          });
          setApplication(result.data.getApplication);
          await handleSetStatuses(result.data.getApplication);
        } catch (err) {
          console.log('error in handleFetchApplication: ', err);
        }
      }
    }
    async function handleSetStatuses(data) {
      setDisclaimerCheck(data.disclaimerCheck);
      setInsuranceInterest(data.insuranceInterest);
    }
    handleFetchApplication();
  }, [userInfo, id]);

  async function handleCreateGBB() {
    if (application) {
      let applicationVersionData = [];
      for (let i = 0; i <= gbb_options.length - 1; i++) {
        let tempData = {};
        tempData = await calculateLoan(
          application.loanAmount,
          gbb_options[i].interestRate,
          gbb_options[i].term,
          application.id,
          gbb_options[i].optionName
        );
        applicationVersionData.push(tempData);
      }
      return applicationVersionData;
    }
  }

  async function handleUpdateApplication(data) {
    try {
      const client = generateClient({ authMode: 'userPool' });
      await client.graphql({
        query: updateApplication,
        variables: { input: data },
      });
    } catch (err) {
      console.log('error in updateApplication: ', err);
    }
  }

  async function handleCreateApplicationVersion() {
    let data = await handleCreateGBB();
    for (let i = 0; i <= data.length - 1; i++) {
      try {
        let tempData = data[i];
        const client = generateClient({ authMode: 'userPool' });
        await client.graphql({
          query: createApplicationVersion,
          variables: { input: tempData },
        });
      } catch (err) {
        console.log('error in createApplication: ', err);
      }
    }
    navigate(`/jjbest/application/app-result/${id}`);
  }

  async function handleDisclaimerClick(data) {
    let applicationData = {};
    const form = document.getElementById("salesforce_form")
    // const url_dev = `http://localhost:3000/jjbest/application/submit/${id}`
    const url_prod = `http://marymontlabs/jjbest/application/submit/${id}`
    form.action += `&retURL=${url_prod}`; // notice the case
    form.submit(); // submit the form
    if (data === 'no' || '') {
      setDisclaimerCheck('yes');
      applicationData = {
        id: id,
        disclaimerCheck: 'yes',
        insuranceInterest: insuranceInterest,
      };
      await handleUpdateApplication(applicationData);
      // navigate(`/jjbest/application/submit/${id}`);
      return true;
    } else {
      setDisclaimerCheck('no');
      applicationData = {
        id: id,
        disclaimerCheck: 'no',
        insuranceInterest: insuranceInterest,
      };
      await handleUpdateApplication(applicationData);
      // navigate(`/jjbest/application/submit/${id}`);
      return true;
    }
  }

  async function handleInsuraceInterestClick(data) {
    let applicationData = {};
    if (data === 'no' || '') {
      setInsuranceInterest('yes');
      applicationData = {
        id: id,
        disclaimerCheck: disclaimerCheck,
        insuranceInterest: 'yes',
      };
      await handleUpdateApplication(applicationData);
    } else {
      applicationData = {
        id: id,
        disclaimerCheck: disclaimerCheck,
        insuranceInterest: 'no',
      };
      setInsuranceInterest('no');
      await handleUpdateApplication(applicationData);
    }
  }

  return userInfo === null || userInfo === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={'8xl'}>
        <Stack
          spacing={{
            base: '8',
            lg: '6',
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              lg: 'row',
            }}
            justify="space-between"
            align={{
              base: 'start',
              lg: 'center',
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: '5',
              lg: '6',
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={'6xl'}>
                <Stack
                  spacing="0"
                  direction={{
                    base: 'column',
                    md: 'row',
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>

              <Box as="section" py={{ base: '4', md: '8' }}>
                <Container maxW="5xl">
                  <Box
                    bg="bg.surface"
                    boxShadow="sm"
                    borderRadius="lg"
                    p={{ base: '4', md: '6' }}
                  >
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '5', md: '6' }}
                      justify="space-between"
                    >
                      <Stack spacing="1">
                        <Text textStyle="lg" fontWeight="medium">
                          I have read, understood and agreed with the following
                          disclaimer:
                        </Text>
                        <Text textStyle="sm" color="fg.muted">
                          I/We have read this application and certify that
                          everything I/We have stated is accurate. J.J. Best
                          Banc & Co. may provide such information set forth in
                          the application and any other information it receives
                          to another creditor for consideration. By submitting
                          this application, I/We give J.J. Best Banc & Co., its
                          corporate affiliates, or its assigns, the
                          authorization to access my/our credit reports,
                          employment history, or any other information sources.
                          I/We understand that the information I/We have
                          provided is given to obtain credit from J.J. Best Banc
                          & Co. or its assignees, and that J.J. Best Banc & Co.
                          will retain this application whether or not it is
                          approved, except as otherwise prohibited by
                          application law or regulation.
                        </Text>
                      </Stack>
                      <Box>
                        <div>
                          <form
                            id='salesforce_form'
                            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00Daj00000F52wk"
                            method="POST"
                          >
                            <input
                              type="hidden"
                              name="oid"
                              value="00Daj00000F52wk"
                            />
                            {/* <input
                              type="hidden"
                              name="retURL"
                              value={`http://localhost:3000/jjbest/application/submit/${id}`}
                            /> */}
                            {/* <input type="hidden" name="debug" value="1" />
                            <input
                              type="hidden"
                              name="debugEmail"
                              value="joe@marymontlabs.com"
                            /> */}
                            <input
                              id="first_name"
                              maxlength="40"
                              name="first_name"
                              size="20"
                              type="hidden"
                              value={application.firstName}
                            />

                            <input
                              id="last_name"
                              maxlength="80"
                              name="last_name"
                              size="20"
                              type="hidden"
                              value={application.lastName}
                            />

                            <input
                              id="email"
                              maxlength="80"
                              name="email"
                              size="20"
                              type="hidden"
                              value={application.email}
                            />

                            <input
                              id="company"
                              maxlength="40"
                              name="company"
                              size="20"
                              type="hidden"
                              value={application.employer}
                            />

                            <input
                              id="city"
                              maxlength="40"
                              name="city"
                              size="20"
                              type="hidden"
                              value={application.city}
                            />

                            <input
                              id="state"
                              maxlength="20"
                              name="state"
                              size="20"
                              type="hidden"
                              value={application.applicantState}
                            />
                            <input
                              id="lead_source"
                              name="lead_source"
                              type="hidden"
                              value="Web"
                            />
                            <input
                              id="description"
                              name="description"
                              type="hidden"
                              value={application.applicationName}
                            />

                            <input
                              id="00Naj000006Ib8v"
                              maxlength="4"
                              name="00Naj000006Ib8v"
                              size="20"
                              type="hidden"
                              value={application.vehicleYear}
                            />
                            <input
                              id="00Naj000006Ia6P" 
                              name="00Naj000006Ia6P"
                              type="hidden"
                              value={application.applicationType}
                            />
                            <input
                              id="00Naj000006IVNl" 
                              name="00Naj000006IVNl"
                              type="hidden"
                              value={application.yearsAtResidence}
                            />
                            <input
                              id="00Naj000006IWxG" 
                              name="00Naj000006IWxG"
                              type="hidden"
                              value={application.residenceType}
                            />
                            <input
                              id="00Naj000006ITFW" 
                              name="00Naj000006ITFW"
                              type="hidden"
                              value={application.collateralType}
                            />
                            <input
                              id="00Naj000006IYXP" 
                              name="00Naj000006IYXP"
                              type="hidden"
                              value={application.make}
                            />
                            <input
                              id="00Naj000006Iab3" 
                              name="00Naj000006Iab3"
                              type="hidden"
                              value={application.model}
                            />
                            <input
                              id="00Naj000006Iakj" 
                              name="00Naj000006Iakj"
                              type="hidden"
                              value={application.purchasePrice}
                            />
                            <input
                              id="00Naj000006IauP" 
                              name="00Naj000006IauP"
                              type="hidden"
                              value={application.loanAmount}
                            />
                            <input
                              id="00Naj000006IbLp" 
                              name="00Naj000006IbLp"
                              type="hidden"
                              value={application.dob}
                            />
                            <input
                              id="00Naj000006IbTt" 
                              name="00Naj000006IbTt"
                              type="hidden"
                              value={application.employmentStatus}
                            />
                            <input
                              id="00Naj000006Ibor" 
                              name="00Naj000006Ibor"
                              type="hidden"
                              value={application.yearsInPosition}
                            />
                            <input
                              id="00Naj000006Ic1l" 
                              name="00Naj000006Ic1l"
                              type="hidden"
                              value={application.employer}
                            />
                            <input
                              id="00Naj000006IZf1" 
                              name="00Naj000006IZf1"
                              type="hidden"
                              value={application.employmentPosition}
                            />
                            <input
                              id="00Naj000006ITFX" 
                              name="00Naj000006ITFX"
                              type="hidden"
                              value={application.yearlyIncome}
                            />
                            <input
                              id="00Naj000006IcWP" 
                              name="00Naj000006IcWP"
                              type="hidden"
                              value={disclaimerCheck}
                            />
                            <input
                              id="00Naj000006IcCl" 
                              name="00Naj000006IcCl"
                              type="hidden"
                              value={insuranceInterest}
                            />
                            <input
                              id="00Naj000006Iut7" 
                              name="00Naj000006Iut7"
                              type="hidden"
                              value={id}
                            />

                            <IconButton
                              // type="submit"
                              // name="submit"
                              onClick={() => handleDisclaimerClick(disclaimerCheck)}
                              // aria-label="Call support"
                              rounded="full"
                              bgColor="secondary.700"
                              color="white"
                            >
                              {disclaimerCheck === 'yes' ? (
                                <MdCheckBox />
                              ) : (
                                <MdCheckBoxOutlineBlank />
                              )}
                            </IconButton>
                          </form>
                        </div>
                      </Box>
                    </Stack>
                  </Box>
                </Container>
              </Box>

              <Box as="section" py={{ base: '4', md: '8' }}>
                <Container maxW="5xl">
                  <Box
                    bg="bg.surface"
                    boxShadow="sm"
                    borderRadius="lg"
                    p={{ base: '4', md: '6' }}
                  >
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '5', md: '6' }}
                      justify="space-between"
                    >
                      <Stack spacing="1">
                        <Text textStyle="lg" fontWeight="medium">
                          I am interested in the JJ Best Insurance Program:
                        </Text>
                        <Text textStyle="sm" color="fg.muted">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Aenean tempor consequat nibh quis iaculis.
                          Etiam.
                        </Text>
                      </Stack>
                      <Box>
                        <IconButton
                          // type="submit"
                          // name="submit"
                          onClick={() => handleInsuraceInterestClick(insuranceInterest)}
                          // aria-label="Call support"
                          rounded="full"
                          bgColor="secondary.700"
                          color="white"
                        >
                          {insuranceInterest === 'yes' ? (
                            <MdCheckBox />
                          ) : (
                            <MdCheckBoxOutlineBlank />
                          )}
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                </Container>
              </Box>

              <Container
                // spacing="5"
                py={{
                  base: '4',
                  md: '8',
                }}
                maxW={'6xl'}
              >
                <Stack spacing={3} direction="column" align="center">
                  <Button
                    mt="8"
                    mb="8"
                    bgColor="secondary.700"
                    colorScheme="blue"
                    px="8"
                    rounded="full"
                    size="md"
                    fontSize="md"
                    fontWeight="bold"
                    type="submit"
                    onClick={handleCreateApplicationVersion}
                  >
                    Submit
                  </Button>
                </Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(SubmitApplication);
