import React, { useEffect, useState } from "react";
import {
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { generateClient } from 'aws-amplify/api';
import Sidebar from "../../../components/pageshell/Sidebar";
import Navbar from "../../../components/pageshell/Navbar";
import protectedRoute from "../../../utils/protectedRoute";
import MyAppItem from "../../../components/jjbest/MyAppItem";
import { listApplications } from "../../../graphql/queries"

const DirectApplications = (props) => {
  const { userInfo } = props;
  const [applications, setApplications] = useState([])
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => { 
    async function fetchApplications(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: listApplications,
          })
          setApplications(result.data.listApplications.items);
        } catch(err){
          console.log("error in createApplication: ", err);
        }
    }
  }
    fetchApplications()
  }, [userInfo]);

  return userInfo === null || undefined || applications === null ||  undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container py="8" flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Stack spacing="1">
              <Heading
                fontWeight="medium"
              >
                Direct applications
              </Heading>
            </Stack>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <SimpleGrid
              columns={{
                base: 1,
                md: 3,
              }}
              gap="6"
            >
              {applications.map((application, idx) => (
                <MyAppItem key={idx} application={application} />
              ))}
            </SimpleGrid>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(DirectApplications);
