import { theme as chakraTheme } from '@chakra-ui/react'
import { extendTheme, theme as base } from "@chakra-ui/react"
//import { createBreakpoints } from "@chakra-ui/theme-tools"

const fonts = {
  heading: `Montserrat, ${base.fonts?.heading}`,
  body: `Montserrat, ${base.fonts?.body}`,
}

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
}

const colors = {
  transparent: "transparent",
    black: "#000",
    gray: {
      50: "#f7fafc",
      // ...
      900: "#1a202c",
    },
  primary: {
    100: "#C2E5F2", // light blue
    // 200: "#90CDF4",
    // 300: "#63B3ED",
    // 400: "#4299E1",
    500: "#2142A6",
    // 600: "#2B6CB0",
    // 700: "#2C5282",
    // 800: "#2A4365",
    900: "#1E3859"
  },
  secondary: {
    300: '#F2A0AF', //pink
    // 500: "#D83B23",
    // 600: "#E40032",
    //600: "#C53030",
    700: "#D9042B" //red
  }, 
  other: {
    400: "#E40032",
    300: '#FC8B03',
    200: '#F5D2AC',
    100: '#738FA7'
  },
  white: {
    100: '#fff'
  },
  cta: {
    100: "#34D70C" //green bottle
  }
};

const overrides = {
    ...chakraTheme,
    fonts,
    colors,
    breakpoints,
    fontWeights: {
        normal: 300,
        medium: 600,
        bold: 700
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
    },
}

const customTheme = extendTheme(overrides)

export default customTheme