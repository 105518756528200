import { useEffect, useState } from "react";
import {
  Container,
  Text,
  Flex,
  Box,
  Input,
  Stack,
  FormLabel,
  FormControl,
  Select,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { updateApplication } from "../../../../../graphql/mutations"
import { getApplication } from "../../../../../graphql/queries"
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_jjbest_stepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";
import protectedRoute from "../../../../../utils/protectedRoute"

const Applicant = (props) => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [application, setApplication] = useState([]);
  const [firstName, setFirstName] = useState("");
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const [lastName, setLastName] = useState("");
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const [ssn, setSSN] = useState("");
  const handleSSNChange = (event) => setSSN(event.target.value);
  const [dob, setDOB] = useState("");
  const handleDOBChange = (event) => setDOB(event.target.value);
  const [email, setEmail] = useState("");
  const handleEmailChange = (event) => setEmail(event.target.value);
  const [cellPhone, setCellPhone] = useState("");
  const handleCellPhoneChange = (event) => setCellPhone(event.target.value);
  const [address, setAddress] = useState("");
  const handleAddressChange = (event) =>
    setAddress(event.target.value);
  const [city, setCity] = useState("");
  const handleCityChange = (event) => setCity(event.target.value);
  const [applicantState, setApplicantState] = useState("")
  const handleApplicantStateChange = (event) => setApplicantState(event.target.value);
  const [zipCode, setZipCode] = useState("");
  const handleZipCodeChange = (event) => setZipCode(event.target.value);
  const [residenceType, setResidenceType] = useState("");
  const handleResidenceTypeChange = (event) => setResidenceType(event.target.value);
  const [residenceTime, setResidenceTime] = useState("");
  const handleResidenceTimeChange = (event) => setResidenceTime(event.target.value);
  const [applicationType, setApplicationType] = useState("");
  const handleApplicationTypeChange = (event) => setApplicationType(event.target.value);

  const residenceTypeOptions = [
    "Own Home",
    "Rent",
    "Other",
  ];

  const timeAtResidenceOptions = [
    "Less than a year",
    "1 to 3 years",
    "3 to 9 years",
    "10+ years"
  ];

  const applicationTypeOptions = [
    "Individual",
    "Joint"
  ];

  const stateNameOptions = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming']


  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 1,
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }

    }
    handleFetchApplication()
  }, [userInfo, id]);

  async function handleUpdateApplication(){
    const applicationData = {
      id: application.id,
      firstName: firstName,
      lastName: lastName,
      ssn: ssn,
      dob: dob,
      email: email,
      cellPhone: cellPhone,
      address: address,
      city: city,
      applicantState: applicantState,
      zipCode: zipCode,
      residenceType: residenceType,
      yearsAtResidence: residenceTime,
      applicationType: applicationType,
    }

    try{
      const client = generateClient({authMode: 'userPool'})
      await client.graphql({
        query: updateApplication,
        variables: { input: applicationData }
      })
    } catch(err){
      console.log("error in updateApplication: ", err);
    }
    navigate(`/jjbest/application/employment/${id}`)
  }

  return userInfo === null || userInfo === undefined ?(
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container
                // spacing="5"
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"6xl"}
              >

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="firstName">
                      <FormLabel>First Name</FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter first name..."
                        value={firstName}
                        name="firstName"
                        onChange={(event) => handleFirstNameChange(event)}
                      />
                    </FormControl>
                    <FormControl id="lastName">
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter model..."
                        value={lastName}
                        name="lastName"
                        onChange={(event) => handleLastNameChange(event)}
                      />
                    </FormControl>
                  </Stack>

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="ssn">
                      <FormLabel>SSN</FormLabel>
                 
                       
                        <Input
                          placeholder="Enter SSN"
                          type="password"
                          value={ssn}
                          name="ssn"
                          onChange={(event) => handleSSNChange(event)}
                        />
              
                    </FormControl>
                    <FormControl id="dob">
                      <FormLabel>Date of Birth</FormLabel>
                     
                      
                        <Input
                          type="text"
                          // placeholder="Enter loan amount..."
                          value={dob}
                          name="dob"
                          onChange={(event) => handleDOBChange(event)}
                        />
            
                    </FormControl>
                  </Stack>
                {/* </Stack> */}

                <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="email">
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        // placeholder="Enter first name..."
                        value={email}
                        name="email"
                        onChange={(event) => handleEmailChange(event)}
                      />
                    </FormControl>
                    <FormControl id="cellPhone">
                      <FormLabel>Cell phone</FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter model..."
                        value={cellPhone}
                        name="cellPhone"
                        onChange={(event) => handleCellPhoneChange(event)}
                      />
                    </FormControl>
                  </Stack>

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="address">
                      <FormLabel>Address</FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter first name..."
                        value={address}
                        name="address"
                        onChange={(event) => handleAddressChange(event)}
                      />
                    </FormControl>
                    
                  </Stack>

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="city">
                      <FormLabel>City</FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter first name..."
                        // value={address}
                        name="city"
                        onChange={(event) => handleCityChange(event)}
                      />
                    </FormControl>
                    <FormControl id="applicantState">
                      <FormLabel>State</FormLabel>
                      <Select
                        placeholder="select a state"
                        size="md"
                        value={applicantState}
                        onChange={handleApplicantStateChange}
                      >
                        {stateNameOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="zipCode">
                      <FormLabel>Zip Code</FormLabel>
                      <Input
                        type="text"
                        // placeholder="Enter model..."
                        value={zipCode}
                        name="zipCode"
                        onChange={(event) => handleZipCodeChange(event)}
                      />
                    </FormControl>
                  </Stack>
                  
                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                   <FormControl id="residenceType">
                      <FormLabel>Residence Type</FormLabel>
                      <Select
                        placeholder="select an residence type"
                        size="md"
                        value={residenceType}
                        onChange={handleResidenceTypeChange}
                      >
                        {residenceTypeOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="residenceTime">
                      <FormLabel>Residence Time</FormLabel>
                      <Select
                        placeholder="select a year"
                        size="md"
                        value={residenceTime}
                        onChange={handleResidenceTimeChange}
                      >
                        {timeAtResidenceOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="applicationType">
                      <FormLabel>Application Type</FormLabel>
                      <Select
                        placeholder="individual or joint?"
                        size="md"
                        value={applicationType}
                        onChange={handleApplicationTypeChange}
                      >
                        {applicationTypeOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                <Stack spacing={3} direction="column" align="center">
                  <Button
                    mt="8"
                    mb="8"
                    bgColor="secondary.700"
                    colorScheme="blue"
                    px="8"
                    rounded="full"
                    size="md"
                    fontSize="md"
                    fontWeight="bold"
                    type="submit"
                    onClick={handleUpdateApplication}
                  >
                    Next
                  </Button>
                </Stack>
                <Stack spacing={3} direction="column" align="center"></Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(Applicant);
