import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  Link,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
// import Logo from '../../components/nav-direct/Logo';



const LoginScreen = (userInfo) => {
  // console.log("userInfo in LoginScreen: ", userInfo)
  const [email, setEmail] = useState('');
  const handleEmailChange = event => setEmail(event.target.value);
  const [password, setPassword] = useState('');
  const handlePasswordChange = event => setPassword(event.target.value);
  const [newPassword, setNewPassword] = useState('');
  const handleNewPasswordChange = event => setNewPassword(event.target.value);
  const [needsChange, setNeedsChange] = useState(false);
  const [firstName, setFirstName] = useState('');
  const handleFirstNameChange = event => setFirstName(event.target.value);
  const [lastName, setLastName] = useState('');
  const handleLastNameChange = event => setLastName(event.target.value);
  const [clientName, setClientName] = useState('');
  const handleClientNameChange = event => setClientName(event.target.value);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();

  const clientNameOptions = ['Forge', 'JJ Best', 'Thompson', 'Admin'];

  useEffect(() => {
    async function handleCheckUser(){
      if(Object.keys(userInfo.userInfo).length !== 0){
        navigate("/dashboard")
      }
    }
    handleCheckUser()
  }, [userInfo, navigate]);

  const handleSignIn = async () => {
    let username = email;
    const { nextStep } = await signIn({ username, password });

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        setNeedsChange(true);
        const userAttributes = {
          name: firstName,
          family_name: lastName,
          nickname: clientName,
        };

        await confirmSignIn({
          challengeResponse: newPassword,
          options: {
            userAttributes,
          },
        });
        navigate('/auth/login');
        break;
      case 'DONE':
        navigate('/dashboard');
        break;
      default:
    }
  };

  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          {/* <Logo /> */}
          <Text
            px="3"
            fontSize="xl"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="widest"
            color="primary.900"
            align="center"
            mb="1"
          >
            Marymont Labs
          </Text>
          <br />
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            {!needsChange ? (
              <Heading
                size={{
                  base: 'xs',
                  md: 'sm',
                }}
              >
                Log in to your account
              </Heading>
            ) : (
              <Heading
                size={{
                  base: 'xs',
                  md: 'sm',
                }}
              >
                Change password and register
              </Heading>
            )}
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg.surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          {!needsChange ? (
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    name="email"
                    onChange={event => handleEmailChange(event)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup size="md">
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                    <Input
                      id="password"
                      pr="4.5rem"
                      type={show ? 'text' : 'password'}
                      placeholder="Enter password"
                      value={password}
                      name="password"
                      onChange={event => handlePasswordChange(event)}
                      autoComplete="current-password"
                      required
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
              <HStack justify="space-between">
                <Checkbox defaultChecked>Remember me</Checkbox>
                <Link href="/auth/forgot-password">
                  <Button variant="text" size="sm">
                    Forgot password?
                  </Button>
                </Link>
              </HStack>
              <Stack spacing="6">
                <Button
                  size="md"
                  bgColor="cta.100"
                  color="white"
                  onClick={handleSignIn}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    name="email"
                    onChange={event => handleEmailChange(event)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="password">Old Password</FormLabel>
                  <InputGroup size="md">
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                    <Input
                      id="password"
                      pr="4.5rem"
                      type={show ? 'text' : 'password'}
                      placeholder="Enter old password"
                      value={password}
                      name="password"
                      onChange={event => handlePasswordChange(event)}
                      autoComplete="current-password"
                      required
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="password">New Password</FormLabel>
                  <InputGroup size="md">
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                    <Input
                      id="password"
                      pr="4.5rem"
                      type={show ? 'text' : 'password'}
                      placeholder="Enter New Password"
                      value={newPassword}
                      name="newPassword"
                      onChange={event => handleNewPasswordChange(event)}
                      autoComplete="current-password"
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    id="firstName"
                    type="text"
                    value={firstName}
                    name="firstName"
                    onChange={event => handleFirstNameChange(event)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    id="lastName"
                    type="text"
                    value={lastName}
                    name="lastName"
                    onChange={event => handleLastNameChange(event)}
                  />
                </FormControl>
                <FormControl id="clientName">
                  <FormLabel>Client Name</FormLabel>
                  <Select
                    placeholder="select a client name"
                    size="md"
                    value={clientName}
                    onChange={handleClientNameChange}
                  >
                    {clientNameOptions.map((type, id) => (
                      <option key={id} type={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack spacing="6">
                <Button
                  size="md"
                  bgColor="cta.100"
                  color="white"
                  onClick={handleSignIn}
                >
                  Change Password
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

export default LoginScreen;
