import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
// import Logo from '../../components/nav-direct/Logo';

const ForgotPasswordScreen = (props) => {
  // console.log("userInfo in LoginScreen: ", userInfo)
  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => setEmail(event.target.value);
  const [passwordCode, setPasswordCode] = useState('');
  const handlePasswordCodeChange = (event) => setPasswordCode(event.target.value);
  const [newPassword, setNewPassword] = useState('');
  const handleNewPasswordChange = (event) => setNewPassword(event.target.value);
  const [needsChange, setNeedsChange] = useState(false);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();

const handleGenerateCode = async () => {
  let username = email;
  await resetPassword({
    username,
  });
  setNeedsChange(true)
}

  const handleForgotPasswordSubmit = async () => {
    let username = email;
    let confirmationCode = passwordCode;
    await confirmResetPassword({
      username,
      newPassword,
      confirmationCode,
    });
    navigate('/auth/login')
  }

  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          {/* <Logo /> */}
          <Text
            px="3"
            fontSize="xl"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="widest"
            color="primary.900"
            align="center"
            mb="1"
          >
            Marymont Labs
          </Text>
          <br />
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            {!needsChange ? (
              <Heading
                size={{
                  base: 'xs',
                  md: 'sm',
                }}
              >
                Get a password change code
              </Heading>
            ) : (
              <Heading
                size={{
                  base: 'xs',
                  md: 'sm',
                }}
              >
                Change password
              </Heading>
            )}
           
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg.surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          {!needsChange ? (
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    name="email"
                    onChange={(event) => handleEmailChange(event)}
                  />
                </FormControl>

               
              </Stack>
              <Stack spacing="6">
                <Button size="md" bgColor="cta.100" color="white" onClick={handleGenerateCode}>
                  Generate Code
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    name="email"
                    onChange={(event) => handleEmailChange(event)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="password">Code from email</FormLabel>
                  <InputGroup size="md">
                    <Input
                      id="passwordCode"
                      pr="4.5rem"
                      type="text"
                      placeholder="Enter code from email"
                      value={passwordCode}
                      name="passwordCode"
                      onChange={(event) => handlePasswordCodeChange(event)}
                      required
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="password">New Password</FormLabel>
                  <InputGroup size="md">
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                    <Input
                      id="password"
                      pr="4.5rem"
                      type={show ? 'text' : 'password'}
                      placeholder="Enter New Password"
                      value={newPassword}
                      name="newPassword"
                      onChange={(event) => handleNewPasswordChange(event)}
                      autoComplete="current-password"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>

              <Stack spacing="6">
                <Button size="md" bgColor="cta.100" color="white" onClick={handleForgotPasswordSubmit}>
                  Change Password
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </Container>
  );
};

export default ForgotPasswordScreen;
