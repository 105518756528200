import { useEffect, useState } from 'react';
import { getUrl } from 'aws-amplify/storage';
import {
  Badge,
  Box,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  Img,
  Skeleton,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { VscCircleFilled } from 'react-icons/vsc';

const BlogPost = props => {
  const { article, isHero, unsignedUrl } = props;
  const [articleImage, setArticleImage] = useState();

  useEffect(() => {
    const getOneFormattedImage = async () => {
      if (unsignedUrl) {
        const signedURL = await getUrl({ key: unsignedUrl });
        setArticleImage(signedURL);
      }
    };
    getOneFormattedImage();
  }, [article, unsignedUrl]);
  return article === null || articleImage === null ? (
    <div></div>
  ) : (
    <Link
      _hover={{ textDecor: 'none' }}
      role="group"
      href={`/jjbest/resources/${article.url}`}
    >
      <Stack spacing="8">
        <Box overflow="hidden">
          {articleImage && (
            <Img
              src={articleImage.url.href}
              alt={article.title}
              width="full"
              fallback={<Skeleton />}
              height={{ base: '15rem', md: isHero ? 'sm' : '15rem' }}
              objectFit="cover"
              transition="all 0.2s"
              _groupHover={{ transform: 'scale(1.05)' }}
            />
          )}
        </Box>
        <Stack spacing="6">
          <Stack spacing="3">
            <HStack
              spacing="1"
              fontSize="sm"
              fontWeight="semibold"
              color="accent"
            >
              <Text>{article.author}</Text>
              <Icon as={VscCircleFilled} boxSize="2" />
              <Text> {article.createdAt}</Text>
            </HStack>
            <Heading size={{ base: 'xs', md: isHero ? 'sm' : 'xs' }}>
              {article.title}
            </Heading>
            <Text color="fg.muted">{article.synopsis}</Text>
          </Stack>
          <HStack>
            <Wrap width="full" spacing="6">
              {article.articleTags.items.map((tag, id) => {
                return (
                  <WrapItem key={tag.id}>
                    <Badge
                      key={id}
                      // colorScheme={tag.color}
                    >
                      {tag.articleTagName}
                    </Badge>
                
                  </WrapItem>
                );
              })}
            </Wrap>
          </HStack>
        </Stack>
      </Stack>
    </Link>
  );
};

export default BlogPost;
