import {
  Stack,
  Divider,
  CircularProgress,
  Box,
  Flex,
  Spacer,
  Heading,
  FormControl,
  Wrap,
  WrapItem,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import LandingLayout from '../../../../../components/layouts/LandingLayout';
import ArticleHero from '../../../../../components/heros/ArticleHero';
import { getArticle } from '../../../../../graphql/queries';
import { deleteArticle } from '../../../../../graphql/mutations';
import GetArticleSectionItems from '../../../../../components/articles/GetArticleSectionItems';

const _ = require('lodash');

const Article = props => {
  const { userInfo } = props;
  const { id } = useParams();
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [articleTags, setArticleTags] = useState([]);
  const [thisArticleSectionData, setThisArticleSectionData] = useState([]);
  const color1 = mode('gray.100', 'gray.800');

  useEffect(() => {
    const handleFetchArticle = async () => {
      if (id) {
        try {
          const client = generateClient({ authMode: 'userPool' });
          let result = await client.graphql({
            query: getArticle,
            variables: { id: id },
          });
          setArticle(result.data.getArticle);
          handleSortedArticleTags(result.data.getArticle.articleTags.items);
          const sectionResult = await result.data.getArticle.articleSections
            .items;
          setThisArticleSectionData(sectionResult);
          setLoading(false);
        } catch (err) {
          console.log('error in fetchArticle: ', err);
        }
      }
    };
    const handleSortedArticleTags = async data => {
      if (id) {
        const sortedArticleTagData = _.sortBy(data, ['articleTags'], ['asc']);
        setArticleTags(sortedArticleTagData);
        // setLoading(false);
      }
    };
    handleFetchArticle();
  }, [userInfo, id]);

  return loading ? (
    <CircularProgress />
  ) : (
    <LandingLayout>
      <Stack spacing={4}>
        {article === null || undefined ? (
          <div></div>
        ) : (
          <ArticleHero article={article} />
        )}

        <Box
          //bg={mode('gray.100', 'gray.800')}
          as="section"
          py={{
            base: '12',
            md: '24',
          }}
        >
          <Box
            maxW={{
              base: 'xl',
              md: '7xl',
            }}
            mx="auto"
            px={{
              base: '6',
              md: '8',
            }}
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              spacing={{
                base: '12',
                xl: '20',
              }}
              //align="center"
            >
              <Box flex="1">
                <GetArticleSectionItems
                  articleSectionData={thisArticleSectionData}
                />
              </Box>
              <Box
                maxW="450px"
                flex="1"
                h={{
                  base: '240px',
                  md: '400px',
                }}
                flexShrink={0}
                align="center"
                //bg={mode('gray.50', 'gray.800')}
              >
                <Flex
                  height="50vh"
                  width={{
                    base: 'full',
                    sm: 'xs',
                  }}
                  direction="column"
                  borderRightWidth="1px"
                  px={6}
                  py={8}
                  bg={color1}
                  align="center"
                >
                  <Stack spacing={6}>
                    {articleTags === null || undefined ? (
                      <div></div>
                    ) : (
                      <RelatedArticleTags articleTags={articleTags} />
                    )}

                    <Divider />
                    {article === null || undefined ? (
                      <div></div>
                    ) : (
                      <DeleteArticle article={article} />
                    )}

                    {/* <SocialButtons /> */}
                  </Stack>
                  <Spacer />
                </Flex>
              </Box>
            </Stack>
          </Box>
        </Box>
        {/* <Footer /> */}
      </Stack>
    </LandingLayout>
  );
};

const RelatedArticleTags = props => {
  const { articleTags } = props;
  const navigate = useNavigate();

  useEffect(() => {}, [articleTags]);

  function handleSearchTagClick(e, tag) {
    e.preventDefault();
    navigate(`/jjbest/resources/searchbytag/${tag.articleTagName}`);
  }

  return articleTags === null || undefined ? (
    <CircularProgress />
  ) : (
    <Flex h="full" direction="column" px="4" py="4">
      <Stack spacing={4}>
        <Heading as="h4" size="md">
          Related Articles
        </Heading>
        <FormControl id="taglist">
          <Wrap width="full" spacing="6">
            {articleTags.map(tag => {
              return (
                <WrapItem key={tag.id}>
                  <Box
                    as="button"
                    key={tag.id}
                    borderRadius="md"
                    bg="secondary.700"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    px={4}
                    py={1}
                    h={8}
                    onClick={e => handleSearchTagClick(e, tag)}
                  >
                    {tag.articleTagName}
                  </Box>
                </WrapItem>
              );
            })}
          </Wrap>
        </FormControl>
      </Stack>
    </Flex>
  );
};

const DeleteArticle = props => {
  const { article } = props;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setLoading(false);
  }, [article]);

  async function handleDeleteArticleClick(e) {
    e.preventDefault();
    const deleteObj = {
      id: id,
    };
    try {
      const client = generateClient({ authMode: 'userPool' });
      await client.graphql({
        query: deleteArticle,
        variables: { input: deleteObj },
      });
    } catch (err) {
      console.log('error deleteArticle: ', err);
    }
    navigate(`/jjbest/articleadmin/0`);
  }

  return loading || article === null || undefined ? (
    <CircularProgress />
  ) : (
    <Flex h="full" direction="column" px="4" py="4">
      <Stack spacing={4}>
        <Heading as="h4" size="md">
          Delete Article
        </Heading>
        <FormControl id="taglist">
          <Box
            as="button"
            borderRadius="md"
            bg="primary.900"
            alignItems="center"
            justifyContent="center"
            color="white"
            px={4}
            py={1}
            h={8}
            onClick={e => handleDeleteArticleClick(e)}
          >
            Delete
          </Box>
        </FormControl>
      </Stack>
    </Flex>
  );
};

export default Article;
