import { useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  Flex,
  Stack,
  Square,
  Heading,
  Text,
  Icon,
  Link,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { FiArrowRight } from "react-icons/fi";
import { FaRegSmile, FaRegSmileWink, FaRegSmileBeam } from "react-icons/fa";
import { getApplication } from "../../../../graphql/queries"
import Sidebar from "../../../../components/pageshell/Sidebar";
import Navbar from "../../../../components/pageshell/Navbar";
import { formatter } from "../../../../utils/currencyFormatter";
import QuoteResultItem from "../../../../components/jjbest/QuoteResultItem";
import { features } from "../../../../data/_jjbest_featureData";
// import DeleteQuoteModal from "../../../components/quote/DeleteQuoteModal";
import protectedRoute from "../../../../utils/protectedRoute"

const CustomizeMyApp = (props) => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const { id } = useParams();
  const [application, setApplication] = useState([])
  // const [loading, setLoading] = useState(true);
  // const [loadingMessage, setLoadingMessage] = useState(
  //   "Fetching and customizing quote..."
  // );
  const [gbbFeatures, setGbbFeatures] = useState([]);
  const color1 = useColorModeValue("sm", "sm-dark");
  const breakpoint1 = useBreakpointValue({
    base: "none",
    md: "lg",
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
          handleCalculateApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }
    }
    const handleCalculateApplication = (applicationData) => {
      let goodData = [];
      let betterData = [];
      let bestData = [];
      if (applicationData) {
        for (let i = 0; i <= applicationData.applicationVersions.items.length - 1; i++) {
          let item = applicationData.applicationVersions.items[i];
          if (item.optionName === "Good") {
            goodData = item;
          } else if (item.optionName === "Better") {
            betterData = item;
          } else {
            bestData = item;
          }
        }
  
        const features = [
          {
            featureId: 1,
            description: "Good for those who want to own it quicker",
            icon: FaRegSmile,
            applicationVersion: goodData.applicationVersionId,
            applicationId: application.id,
            monthlyPayment: goodData.monthlyPayment,
            totalInterest: goodData.totalInterest,
            totalPayments: goodData.totalPayments,
            loanTerm: goodData.loanTerm,
            interestRate: goodData.interestRate,
          },
          {
            featureId: 2,
            description: "A better monthly payment with a manageable payoff time",
            icon: FaRegSmileWink,
            applicationVersion: betterData.applicationVersionId,
            applicationId: application.id,
            monthlyPayment: betterData.monthlyPayment,
            totalInterest: betterData.totalInterest,
            totalPayments: betterData.totalPayments,
            loanTerm: betterData.loanTerm,
            interestRate: betterData.interestRate,
          },
          {
            featureId: 3,
            description: "Lowest monthly payments. Period.",
            icon: FaRegSmileBeam,
            applicationVersion: bestData.applicationVersionId,
            applicationId: application.id,
            monthlyPayment: bestData.monthlyPayment,
            totalInterest: bestData.totalInterest,
            totalPayments: bestData.totalPayments,
            loanTerm: bestData.loanTerm,
            interestRate: bestData.interestRate,
          },
        ];
        setGbbFeatures(features);
      }
    }
    handleFetchApplication();
  }, [userInfo, id, application.id]);

  return userInfo === null || undefined || application === null || undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container py="8" flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          {/* <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          ></Stack> */}
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"8xl"}
              >
                <Box
                  bg="bg-surface"
                  boxShadow={{
                    base: "none",
                    md: color1,
                  }}
                  borderRadius={breakpoint1}
                >
                  <Stack spacing="5">
                    <Box
                      px={{
                        base: "4",
                        md: "6",
                      }}
                      pt="5"
                      pb="5"
                      bgColor="gray.50"
                    >
                      <Stack
                        direction={{
                          base: "column",
                          md: "row",
                        }}
                        justify="space-between"
                      >
                        <Text fontSize="lg" fontWeight="medium">
                          {application.applicationName}
                        </Text>
                        <Text fontSize="lg" fontWeight="medium">
                          Purchase Price: {formatter.format(application.purchasePrice)}
                        </Text>
                        <Text fontSize="lg" fontWeight="medium">
                          Loan Amount: {formatter.format(application.loanAmount)}
                        </Text>
                      </Stack>
                    </Box>
                    <Box overflowX="auto">
                      <IndicativeQuoteScreen
                        userInfo={userInfo}
                        gbbFeatures={gbbFeatures}
                        application={application}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Container>
              {/* <Box
                as="section"
                py={{
                  base: "4",
                  md: "8",
                }}
              >
                <Container maxW="3xl">
                  <Box
                    w="full"
                    h="full"
                    rounded="lg"
                    border="2px solid currentColor"
                    bg="bg-surface"
                    boxShadow={color1}
                    borderRadius="lg"
                    p={{
                      base: "4",
                      md: "6",
                    }}
                  >
                    <Stack spacing="5">
                      <Stack
                        direction={{
                          base: "column",
                          md: "row",
                        }}
                        justify="space-between"
                      >
                        <Text fontSize="lg" fontWeight="medium">
                          Danger Zone
                        </Text>
                        <DeleteQuoteModal id={id} />
                      </Stack>
                    </Stack>
                  </Box>
                </Container>
              </Box> */}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

const IndicativeQuoteScreen = (props) => {
  const { gbbFeatures, application } = props;
  return (
    <Container maxW={"8xl"}>
      <Stack
        spacing={{
          base: "12",
          md: "16",
        }}
      >
        <Stack
          spacing={{
            base: "4",
            md: "5",
          }}
          maxW="3xl"
        >
          <Stack spacing="3">
            <Heading color="primary.900">
              {application.firstName} {application.lastName}
            </Heading>
          </Stack>
          {/* <Text
            color="muted"
            fontSize={{
              base: "lg",
              md: "xl",
            }}
          >
            Here are 3 indicative quotes for your {application.vehicleYear}{" "}
            {application.make} {application.model}
          </Text> */}
        </Stack>
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          spacing={{
            base: "12",
            lg: "16",
          }}
        >
          <Stack
            spacing={{
              base: "4",
              md: "8",
            }}
            maxW="xl"
            justify="center"
            width="full"
          >
            {gbbFeatures.map((feature, idx) => (
              <Stack key={idx} spacing="4" direction="row">
                <Square
                  size={{
                    base: "20",
                    md: "24",
                  }}
                  bg="accent"
                  color="inverted"
                  borderRadius="lg"
                >
                  <Icon
                    color="secondary.700"
                    as={feature.icon}
                    boxSize={{
                      base: "8",
                      md: "10",
                    }}
                  />
                </Square>
                <Stack
                  spacing={{
                    base: "1",
                    md: "1",
                  }}
                  // pt={{
                  //   base: '1.5',
                  //   md: '2.5',
                  // }}
                >
                  <Stack
                    spacing={{
                      base: "1",
                      md: "2",
                    }}
                  >
                    <Heading size="md">
                      {formatter.format(feature.monthlyPayment)} Monthly Payment
                    </Heading>

                    <Text
                      fontSize={{
                        base: "lg",
                        md: "xl",
                      }}
                      fontWeight="medium"
                    >
                      {feature.description}
                    </Text>
                    <Text color="muted">
                      {feature.interestRate}% for {feature.loanTerm} years
                    </Text>
                    <Text color="muted">
                      Total Interest: {formatter.format(feature.totalInterest)}{" "}
                      Total Payments: {formatter.format(feature.totalPayments)}
                    </Text>
                  </Stack>
                  <Link
                    href={`/jjbest/application/contact-us/${feature.applicationId}`}
                  >
                    <Button
                      variant="link"
                      colorScheme="blue"
                      rightIcon={<FiArrowRight fontSize="1.25rem" />}
                      alignSelf="start"
                    >
                      Customize
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Box width="full" overflow="hidden">
            <Stack
              spacing={{ base: "6", md: "10" }}
              align="center"
              p="2"
              bgColor="gray.50"
            >
              <Heading size="md" color="secondary.700">
                Don&apos;t Just Admire It. Own it.
              </Heading>
              {/* <Text
                align="center"
                fontSize={{
                  base: "md",
                  md: "lg",
                }}
                fontWeight="medium"
              >
                As the nation&apos;s oldest and most experienced classic and exotic car lender, we deeply understand the collector market and we always look out for your best interests.
              </Text> */}
              <Stack spacing="8">
                <Stack spacing="6">
                  {features.slice(0, 4).map((feature, idx) => (
                    <Stack key={idx}>
                      <QuoteResultItem feature={feature} />
                  </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default protectedRoute(CustomizeMyApp);
