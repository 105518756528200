import { Stack, Wrap, WrapItem, Center, Image } from "@chakra-ui/react";

const EmployerCard = (props) => {
  const { image } = props;
  return (
    <Stack spacing="3" flex="1">
      <Wrap>
        <WrapItem>
          <Center w="180px" h="80px">
            <Image src={image} alt="Forge" />
          </Center>
        </WrapItem>
      </Wrap>
      {/* <Text
        textStyle={{
          base: '5xl',
          md: '6xl',
        }}
        color="accent"
        fontWeight="semibold"
      >
        {value}
      </Text> */}
      {/* <Stack spacing="5">
        <Stack>
          <Text textStyle="lg" fontWeight="semibold">
            {label}
          </Text>
          <Text color="fg.muted">{description}</Text>
        </Stack>
        
      </Stack> */}
    </Stack>
  );
};

export default EmployerCard;
