import { Heading, Box } from '@chakra-ui/react'
import LandingLayout from '../components/layouts/LandingLayout'
import Footer from '../components/nav-direct/Footer'

const Custom404 = () => {
  return (
    <LandingLayout>
        <Box p="6">
        <Heading as="h1" >404 - Page Not Found  </Heading>
        </Box>
    <Footer />
  </LandingLayout>
  
  )
}

export default Custom404