import React, { useState, useEffect, Fragment } from 'react';
import { Heading, Text, List, ListItem, CircularProgress, Divider, Stack, useColorModeValue } from '@chakra-ui/react'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from 'react-markdown'

const _ = require('lodash');

const newTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'16px'}>
        {children}
      </Text>
    );
  },
};

const GetArticleSectionItems = (props) => {
  const { articleSectionData } = props
  const [articleSections, setArticleSections] = useState([])
  const [loading, setLoading] = useState(true)
  const color = useColorModeValue('blackAlpha.800', 'whiteAlpha.800')

  useEffect(() => {  
    async function handleSortedSections() {
      if(articleSectionData === undefined){
        //do nothing
      } else {
        const sortedArticleSectionData = _.sortBy(articleSectionData, ['sectionOrder'], ['asc'])
        setArticleSections(sortedArticleSectionData);
        setLoading(false)
      }
    }
    handleSortedSections()
  }, [articleSectionData])

  return (loading || articleSections === null || undefined ? <CircularProgress /> :
    <List spacing={3}>
      {articleSections.map((data) =>((
          <Fragment key={data.id}>
            <ListItem >
            <Heading as="h1" size="lg" color={color} mb="2">
             {data.sectionHeader} 
           </Heading>
           <Stack
            spacing="5"
            lineHeight="1.75"
            maxW="65ch"
            color={color}
            >
                <ReactMarkdown components={ChakraUIRenderer(newTheme)} children={data.sectionContentMarkup} skipHtml />;
            </Stack>
           
         </ListItem>
         </Fragment>
           )
  ))

     }
     
 <Divider />
    
 </List>
    
    )
}

export default GetArticleSectionItems
