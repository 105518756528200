import { Stack, Text, HStack, Square, Icon } from "@chakra-ui/react";

const QuoteResultItem = (props) => {
  const { feature } = props;
  return (
    <HStack spacing="3" flex="1">
      <Square
        size={{ base: "10", md: "12" }}
        bg="accent"
        color="fg.inverted"
        borderRadius="lg"
      >
        <Icon
          as={feature.icon}
          boxSize={{ base: "10", md: "12" }}
          color="secondary.700"
        />
      </Square>
      <Stack spacing={{ base: "1", md: "2" }} flex="1">
        <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="medium">
          {feature.name}
        </Text>
        <Text color="fg.muted">{feature.description}</Text>
      </Stack>
    </HStack>
  );
};

export default QuoteResultItem;
