import { useEffect } from "react";
import {
  Box,
  Text,
  Container,
  Stack,
  HStack,
  StackDivider,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { formatter } from "../../utils/currencyFormatter";

const MyAppItem = (props) => {
  const navigate = useNavigate();
  const { application } = props;

  useEffect(() => {}, [application]);

  async function handleQuoteItemClick(application) {
    navigate(`/jjbest/direct-apps/${application.id}`)
  }

  return (
    <Box
      as="button"
      borderRadius="md"
      bg="gray.100"
      _hover={{ bg: "gray.300" }}
      color="primary.900"
      onClick={() => handleQuoteItemClick(application)}
      key={application.appliationId}
    >
      <Container maxW="3xl">
        <Box
          bg="bg-surface"
          boxShadow={useColorModeValue("sm", "sm-dark")}
          borderRadius="lg"
          p={{
            base: "1",
            md: "2",
          }}
        >
          <Stack spacing="2" divider={<StackDivider />}>
            <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                <Text color="muted" fontWeight="medium">
                  Application Name:{" "}
                </Text>
                <Text color="muted" fontWeight="medium">
                  &nbsp;{application.applicationName}
                </Text>
              </HStack>
            </Stack>
            <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                <Text color="muted" fontWeight="medium">
                  Vehicle:{" "}
                </Text>
                <Text color="muted">&nbsp;{application.vehicleYear} {application.make} {application.model}</Text>
              </HStack>
            </Stack>
            <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                <Text color="muted" fontWeight="medium">
                  Purchase Price:{" "}
                </Text>
                <Text color="muted">&nbsp;{formatter.format(application.purchasePrice)}</Text>
              </HStack>
            </Stack>
            <Stack justify="space-between" direction="row" spacing="1">
              <HStack spacing="0.5" fontSize="sm">
                <Text color="muted" fontWeight="medium">
                  Loan Amount:{" "}
                </Text>
                <Text color="muted">&nbsp;{formatter.format(application.loanAmount)}</Text>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default MyAppItem;
