import { useEffect, useState } from "react";
import {
  Container,
  Text,
  Flex,
  Box,
  Input,
  Stack,
  FormLabel,
  FormControl,
  Select,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { updateApplication } from "../../../../../graphql/mutations"
import { getApplication } from "../../../../../graphql/queries"
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_jjbest_stepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";
import protectedRoute from "../../../../../utils/protectedRoute"

const Employer = (props) => {
  const { userInfo } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [application, setApplication] = useState([])
  const [employmentStatus, setEmploymentStatus] = useState("");
  const handleEmploymentStatusChange = (event) => setEmploymentStatus(event.target.value);
  const [employer, setEmployer] = useState("");
  const handleEmployerChange = (event) => setEmployer(event.target.value);
  const [employmentPosition, setEmploymentPosition] = useState("");
  const handleEmploymentPositionChange = (event) => setEmploymentPosition(event.target.value);
  const [yearlyIncome, setYearlyIncome] = useState("");
  const handleYearlyIncomeChange = (event) => setYearlyIncome(event.target.value);
  const [yearsInPosition, setYearsInPosition] = useState("");
  const handleYearsInPositionChange = (event) => setYearsInPosition(event.target.value)
  

  const employmentStatusOptions = [
    "Employed",
    "Self Employed",
    "Retired",
    "Other",
  ];

  const timeAtEmpoyerOptions = [
    "Less than a year",
    "1 to 3 years",
    "3 to 9 years",
    "10+ years"
  ];


  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 2,
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }

    }
    handleFetchApplication()
  }, [userInfo, id]);

  async function handleUpdateApplication(){
    const applicationData = {
      id: id,
      employmentStatus: employmentStatus,
      employer: employer,
      employmentPosition: employmentPosition,
      yearlyIncome: yearlyIncome,
      yearsInPosition: yearsInPosition,
    }

    try{
      const client = generateClient({authMode: 'userPool'})
      await client.graphql({
        query: updateApplication,
        variables: { input: applicationData }
      })
    } catch(err){
      console.log("error in updateApplication: ", err);
    }
    navigate(`/jjbest/application/images/${id}`)

  }


  return userInfo === null || userInfo === undefined || application === undefined ? (
    <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container
                // spacing="5"
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"6xl"}
              >

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="employmentStatus">
                      <FormLabel>Employment Status</FormLabel>
                      <Select
                        placeholder="select an employment status"
                        size="md"
                        value={employmentStatus}
                        onChange={handleEmploymentStatusChange}
                      >
                        {employmentStatusOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl id="yearlyIncome">
                      <FormLabel>Yearly Income</FormLabel>
                      <Input
                        type="number"
                        // placeholder="Enter model..."
                        value={yearlyIncome}
                        name="yearlyIncome"
                        onChange={(event) => handleYearlyIncomeChange(event)}
                      />
                    </FormControl>
                    <FormControl id="yearsInPosition">
                      <FormLabel>Years at Employer</FormLabel>
                      <Select
                        placeholder="select an employment time"
                        size="md"
                        value={yearsInPosition}
                        onChange={handleYearsInPositionChange}
                      >
                        {timeAtEmpoyerOptions.map((type, id) => (
                          <option key={id} type={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    spacing="6"
                    direction={{
                      base: "column",
                      md: "row",
                    }}
                  >
                    <FormControl id="employer">
                      <FormLabel>Employer</FormLabel>
                 
                       
                        <Input
                          placeholder="Employer Name"
                          type="text"
                          value={employer}
                          name="employer"
                          onChange={(event) => handleEmployerChange(event)}
                        />
              
                    </FormControl>
                    <FormControl id="employmentPosition">
                      <FormLabel>Position</FormLabel>
                      
                       
                        <Input
                          type="text"
                          // placeholder="Enter loan amount..."
                          value={employmentPosition}
                          name="employmentPosition"
                          onChange={(event) => handleEmploymentPositionChange(event)}
                        />
            
                    </FormControl>
                  </Stack>
                {/* </Stack> */}

                <Stack spacing={3} direction="column" align="center">
                  <Button
                    mt="8"
                    mb="8"
                    bgColor="secondary.700"
                    colorScheme="blue"
                    px="8"
                    rounded="full"
                    size="md"
                    fontSize="md"
                    fontWeight="bold"
                    type="submit"
                    onClick={handleUpdateApplication}
                  >
                    Next
                  </Button>
                </Stack>
                <Stack spacing={3} direction="column" align="center"></Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(Employer);
