import classic from "../assets/photos/classic-landscape.jpg";
import kit from "../assets/photos/kit-landscape.jpg";
import muscle from "../assets/photos/muscle-landscape.jpg"
import pickup from "../assets/photos/pickup-landscape.jpg";
import sports from "../assets/photos/sports-landscape.jpg"


export const collector_cars = [
  {
    id: '1',
    name: 'Classic',
    description: 'The cars that turn heads when you drive by, where you can’t help but look and admire just a little bit longer.',
    image: classic,
  },
  {
    id: '2',
    name: 'Hot Rods',
    description: '1932 Ford Coupe. 1950 Mercury Lead Sled, 1934 Chevrolet Sedan Delivery. 1937 Ford Woody Wagon. Old 1932 Ford Coupe. 1950 Mercury Lead Sled, 1934 Chevrolet Sedan Delivery. 1937 Ford Woody Wagon. Old school power, and old school cool. Need we go on',
    image: pickup
  },
  {
    id: '3',
    name: 'Kit',
    description: "Full assembly required — so you know the car is built right and exactly how you want it. Unique, curated parts make these cars truly irreplaceable.",
    image: kit,
  },
  {
    id: '4',
    name: 'Muscle',
    description: "Full assembly required — so you know the car is built right and exactly how you want it. Unique, curated parts make these cars truly irreplaceable.",
    image: muscle,
  },
  {
    id: '5',
    name: 'Sports / Exotic',
    description: "The comfort to cruise, with the power to race, all at your fingertips. Small two-seaters, purchased for the main purpose of touring. Think Alfa Romeo, BMW, Maserati or Porsche.",
    image: sports,
  },
];