import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react'
import * as React from 'react'
//import { Logo } from './Logo'
import Sidebar from './Sidebar'
import ToggleButton from './ToggleButton'

const Navbar = (props) => {
  const { groups, userInfo, authenticated } = props
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <Box
      width="full"
      py="4"
      px={{
        base: '4',
        md: '8',
      }}
      bg="bg-accent"
    >
      <Flex justify="space-between">
    
        <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          isFullHeight
          preserveScrollBarGap // Only disabled for showcase
          trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Sidebar groups={groups} userInfo={userInfo} authenticated={authenticated} />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  )
}

export default Navbar