import {
  Stack,
  Text,
  Icon,
  Link,
  Square,
  useColorModeValue as mode,
} from "@chakra-ui/react";
// import NextLink from "next/link";

const NavItem = (props) => {
  const { client_label, href, icon } = props;
  return (
    <Link href={href}>
      <Stack spacing="3" flex="1">
        <Square
          as="button"
          borderWidth="1x"
          borderRadius="lg"
          size="200px"
          rounded="lg"
          border="3px solid currentColor"
          borderColor={mode("gray.200", "gray.700")}
        >
          <Icon boxSize={20} color="secondary.700">
            {icon}
          </Icon>
          <Stack spacing="5">
            <Stack>
              <Text textStyle="lg" fontWeight="semibold">
                {client_label}
              </Text>
              {/* <Text color="fg.muted">{description}</Text> */}
            </Stack>
          </Stack>
        </Square>
      </Stack>
    </Link>
  );
};

export default NavItem;
