import { Box, Skeleton, Img } from '@chakra-ui/react'
import * as React from 'react'

export const BlogMedia = (props) => {
  const { src, alt, ...rest } = props
  return (
    <Box pos="relative" cursor="pointer" className="group" h="400px" overflow="hidden" {...rest}>

      <Img
        w="full"
        h="full"
        fallback={<Skeleton />}
        objectFit="cover"
        htmlWidth="672"
        htmlHeight="448"
        src={src.src.url.href}
        alt={alt}
        transition="all 0.2s"
        _groupHover={{
          transform: 'scale(1.05)',
        }}
      />

{/* <Image
          // src="https://images.unsplash.com/photo-1589156229687-496a31ad1d1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"
          src={src.src.url.href}
          alt={alt}
          fallback={<Skeleton />}
          //maxH="450px"
          //minW="300px"
          width={600}
            height={400}
            layout="intrinsic"
            objectFit="cover"
            objectPosition="bottom center"
            priority
          //flex="1"
        />  */}
       
    </Box> 
  )
}

//export default BlogMedia