import { Container, Stack, Heading, SimpleGrid } from '@chakra-ui/react';
import { testimonials } from '../../data/_jjbest_testamonial';
import Testimonial from './Testimonial';

const JJBestTestimonials = () => (
    <Container maxW={'8xl'} p="5" bgColor="gray.50">
      <Stack spacing="3" mb="10">
      <Heading size={{ base: 'md', md: 'xl' }}>Testimonials</Heading>
      </Stack>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3 }}
        columnGap={8}
        rowGap={{ base: 10, md: 16 }}
      >
        {testimonials.map((testimonial, id) => (
          <Testimonial key={id} {...testimonial} />
        ))}
      </SimpleGrid>
    </Container>
);

export default JJBestTestimonials;
