import { Flex, HStack, Text } from '@chakra-ui/react'
// import Image from 'next/image'
import * as React from 'react'

export const BlogAuthor = (props) => {
  const { image, name, role, ...rest } = props
  return (
    <HStack spacing="4" {...rest}>
      
      <Flex direction="column">
        <Text fontWeight="medium">{name}</Text>
        <Text fontSize="sm" color="gray.500">
          {role}
        </Text>
      </Flex>
    </HStack>
  )
}